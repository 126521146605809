import React, { Component, useEffect, useState } from 'react';
import AppLayout from '../AppLayout'
import { useTranslation, initReactI18next } from "react-i18next";
import { PageHeader } from 'antd';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Row, Col, message, Spin } from 'antd';
// form component
import { EmailInputItem, SubmitButtonItem, TextInputItem, EmailSentMsg, VerifyForgotUsernameSuccessMsg } from './FormComponents'
//header page go back 
// import { GoBackPageHeader } from './PageHeader';

//call api
import { forgetUsername, userVerifyCode } from '../../core/HandleService.js';

function ForgetUsernameForm() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [requestCode, setRequestCode] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [verifySuccess, setverifySuccess] = useState(false);
  const [responseData, setResponseData] = useState(false);
  const [tempEmail, setTempEmail] = useState();

  async function call_api_forget_username(data) {
    setLoading(true);
    setEmailSent(false);
    const result = await forgetUsername(data);
    if (result?.success) {
      // message.success('send success, please check your email')
      message.success(t('loginForm.check_email'))
      setResponseData(result?.data);
      setRequestCode(true)
      setEmailSent(true)
    } else {
      message.error(result?.message)
    }
    setLoading(false);
  }

  async function call_api_code(data) {
    const { token } = responseData;
    const { code } = data;
    const params = {
      token: token,
      code: code,
      type: 'forgot_username'
    }
    // console.log(params);
    setLoading(true);
    const result = await userVerifyCode(params);
    if (result?.success) {
      message.success(t('forGotUsername.verifySuccess'))
      setverifySuccess(true)
      setTimeout(() => {
        history.push('/home')
      }, 2000);
    } else {
      message.error(result?.message)
    }
    setLoading(false);
    setEmailSent(false);
  }

  const onFinish = (values) => {
    // console.log('Success:', values);
    // message.success('send success')

    if (requestCode) {
      call_api_code(values)
    } else {
      setTempEmail(values)
      call_api_forget_username(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Spin spinning={loading}>
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{ width: '100%', padding: '10%' }}
      >
        <h1>{t('loginForm.forgetUsernameTitle')}</h1>

        <EmailInputItem nameKey='email' placeholder={t('forgetPasswordForm.email')} msg={t('forgetPasswordForm.msg')} />
        {
          requestCode ?
            <Row>
              <Col span={18}>
                <TextInputItem nameKey='code' placeholder={t('verify.verify_code')} msg={t('verify.msg')} />
              </Col>
              <Col span={6}>
                <Button onClick={() => { call_api_forget_username(tempEmail) }}>{t('verify.resend_verify_code')}</Button>
              </Col>
            </Row>
            :
            ''
        }
        {
           emailSent ?
            <EmailSentMsg ScreenWidth={window.innerWidth} />
            :
            null
        }
        {
           verifySuccess ? 
            <VerifyForgotUsernameSuccessMsg/>
            :
            null
        }
        <SubmitButtonItem name={t('forgetPasswordForm.submit')} />

      </Form>
    </Spin>
  )
}
export default ForgetUsernameForm;