import Base64Downloader from 'common-base64-downloader-react';
import { getPolicyDoc } from "../core/HandleService";
import React, { Component, useEffect, useState } from 'react';
import { Spin, message, Row, Col, Button } from 'antd'
import { DisplayCol } from './PolicyDetail';
import { useTranslation, initReactI18next } from "react-i18next";

const button_styles = {
  backgroundColor: '#C8CFD6',
  border: 'unset',
  padding: '1% 3% ',
  borderRadius:8
}
export default function ConvertPDF({ pol_no }) {
  const { t, i18n } = useTranslation();

  // console.log('pol_no', pol_no);
  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem('userData'));
  const [pdfData, setPdfData] = useState();
  const [pdfData2, setPdfData2] = useState();
  const [pdfData3, setPdfData3] = useState();
  const [pdfDataLink, setPdfDataLink] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    call_api_policyDoc();
  }, []);

  async function call_api_policyDoc() {
    setLoading(true);

    const { userinfo } = userData;
    const params = {
      hkid: userinfo.hkid,
      policy_no: pol_no
    }
    const result = await getPolicyDoc(params);
    if (result?.success) {
      // console.log(result.data.policies.pol_schedule)
      // localStorage.setItem('tempPDF', result.data.policies.pol_schedule);
      // convertPDF(result.data.policies.pol_schedule)
      setPdfData(result.data.policies.pol_schedule)
      setPdfData2(result.data.policies.pol_debitnote)
      setPdfData3(result.data.policies.pol_prem_letter)
      setPdfDataLink(result.data.policies.pol_jkt_link)
      setIsModalVisible(true)
    } else {
      message.error(result?.message)
    }

    setLoading(false);
  }

  return (
    <Spin spinning={loading}>
      {
        isModalVisible
          ?
          <div>
            <DownloadPdf temp={pdfData} filename={'Policy Schedule'} />
            <br />
            <DownloadPdf temp={pdfData2} filename={'Debit Note'} />
            {/* <br />
            {openPdf(pdfData2, 'debitnote')}
            <br />
            {openPdf(pdfData, 'schedule')} */}
            <br />
            <button onClick={() => { window.open(pdfDataLink) }} >{t('policy_display.pdf_jacket')}</button>
            <br />
            {(pdfData3 != '') ? <DownloadPdf temp={pdfData3} filename={'Premium Pavment Letter'} /> : ''}
          </div>
          :
          ''
      }
    </Spin>
  )
}



const openPdf = (sample_base64, filename) => {
  // const sample_base64 = localStorage.getItem('tempPDF')
  if (sample_base64 == null) return;

  function openBase64NewTab(base64Pdf) {
    var blob = base64toBlob(base64Pdf);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
  }

  function base64toBlob(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/pdf" });
  }

  return (
    <button onClick={() => { openBase64NewTab(sample_base64) }}>open {filename}</button>
  )
}
const DownloadPdf = ({ temp, filename }) => {
  // var temp = localStorage.getItem('tempPDF');
  if (temp == null || temp == "") return '-';
  if (!temp.includes('base64')) {
    temp = "data:application/pdf;base64," + temp;
  }

  return (
    <Base64Downloader base64={temp} downloadName="pdfDownload" className={'policy_download_btn'} style={{backgroundColor:'#C8CFD6'}} >
      {filename}
    </Base64Downloader>
  )
}

export const DocButton = ({ pol_no }) => {
  const [click, setClick] = useState(false)
  const { t, i18n } = useTranslation();

  return (
    <>
      {/* <Button type="text" onClick={() => { setClick(true) }} disabled={click} style={{ borderRadius: '5px', backgroundColor: '#A29463' }}>{t('policy_display.schedule')}</Button><br /><br />
      {
        click ?
          <ListDoc pol_no={pol_no} />
          :
          ''
      } */}
      {
        <div className={'policy_profile_display'} style={{display:'flex',margin:0,padding:0}}>
        <div disabled className="policy_document_title"style={{}}> {t('policy_display.schedule')} </div>
        </div>
      }
      <br /><br />
      <ListDoc pol_no={pol_no} />

    </>
  )
}

export const ListDoc = ({ pol_no }) => {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem('userData'));
  const [pdfData, setPdfData] = useState();
  const [pdfData2, setPdfData2] = useState();
  const [pdfData3, setPdfData3] = useState();
  const [pdfData4, setPdfData4] = useState();
  const [pdfData5, setPdfData5] = useState();
  const [pdfDataLink, setPdfDataLink] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    call_api_policyDoc();
  }, []);

  async function call_api_policyDoc() {
    setLoading(true);

    const { userinfo } = userData;
    const params = {
      hkid: userinfo.hkid,
      policy_no: pol_no
    }
    const result = await getPolicyDoc(params);
    if (result?.success) {
      // console.log(result.data.policies.pol_schedule)
      // localStorage.setItem('tempPDF', result.data.policies.pol_schedule);
      // convertPDF(result.data.policies.pol_schedule)
      setPdfData(result.data.policies.pol_schedule)
      setPdfData2(result.data.policies.pol_debitnote)
      setPdfData3(result.data.policies.pol_prem_letter)
      setPdfData4(result.data.policies.pol_covernote)
      setPdfData5(result.data.policies.pol_cert)
      setPdfDataLink(result.data.policies.pol_jkt_link)
      setIsModalVisible(true)
    } else {
      message.error(result?.message)
    }
    setLoading(false);
  }
  return (
    <Spin spinning={loading}>
      {
        isModalVisible ?
          <Row gutter={[16, 8]} className="policy_profile_display">
            <DisplayCol keyname={t('policy_display.pdf_schedule') + ':'} data={<DownloadPdf temp={pdfData} filename={t('policy_display.download')} />} num={0} />
            <DisplayCol keyname={t('policy_display.pdf_note') + ':'} data={<DownloadPdf temp={pdfData2} filename={t('policy_display.download')} />} num={0} />
            <DisplayCol keyname={t('policy_display.pdf_jacket') + ':'} data={<button className={'policy_download_btn'} style={{backgroundColor:'#C8CFD6'}} onClick={() => { window.open(pdfDataLink) }} >{t('policy_display.download')}</button>} num={0} />
            {(pdfData3 != '') ? <DisplayCol keyname={t('policy_display.pdf_prem_letter') + ':'} data={<DownloadPdf temp={pdfData3} filename={t('policy_display.download')} />} num={0} /> : ''}
            {pdfData4 ? <DisplayCol keyname={t('policy_display.pdf_cover_note') + ':'} data={<DownloadPdf temp={pdfData4} filename={t('policy_display.download')} />} num={0} /> : ''}
            {pdfData5 ? <DisplayCol keyname={t('policy_display.pdf_cert') + ':'} data={<DownloadPdf temp={pdfData5} filename={t('policy_display.download')} />} num={0} /> : ''}
          </Row>
          :
          ''
      }
    </Spin>
  )
}
