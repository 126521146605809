import React, { Component, useEffect, useState } from 'react';
import { Layout, Button, Row, Col, Divider, Breadcrumb } from 'antd';
import { useTranslation, initReactI18next } from "react-i18next";
// import * as ServiceCall from '../core/ServiceCall';
import { userLogin } from "../core/HandleService";
import LoginForm from '../component/form/LoginForm';
import LangButton from '../component/ChangeLangButton'
import { PhoneFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import AppLayout from '../component/AppLayout'
import HomeAppLayout from '../component/HomeAppLayout'

// logout api
import { userLogout } from '../core/HandleService'

// policy list
import PolicyList from '../component/PolicyList'
// go back 
import { GobackPage } from '../component/PageHeader'

export const policy_title_style = {
  borderRadius: '10px 10px 0px 0px', fontSize: '20px', boxShadow: '3px 3px 9px #9BA3AA'
}

function UserPolicyPage() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [ScreenSize,setScreenSize]=useState();
  const resize=()=>{
    setScreenSize({
      width:window.innerWidth,
      height:window.innerHeight,
    })
  }
  useEffect(() => {
    //clear localStorage accessToken
    window.addEventListener("resize", resize)
  }, []);

  const Display_breadcrumb = () => {
    return (
      <div className="policy_profile_display">
        <div style={{ padding: '1%', color: 'rgb(145 145 145)'}}>{t('policy.title')}</div>
        {/* <Breadcrumb separator=">" style={{ padding: '1%' }}>
          <Breadcrumb.Item >{t('policy.title')}</Breadcrumb.Item>
        </Breadcrumb> */}
      </div>
    )
  }

  return (
    <AppLayout>
      {/* <GobackPage/> */}
      <Display_breadcrumb />
      <div style={{ padding: '4%' }}>
        {/* <button disabled style={policy_title_style}>{t('policy.title')}</button> */}
        <PolicyList />
      </div>

    </AppLayout>
  )

}

export default UserPolicyPage;
