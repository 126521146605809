export function userLogin({ username, password, code, token }) {
  const params = {
    // 'grant_type': 'password',
    // 'client_id': '4',
    // 'client_secret': 'VTmghVkxmeQG1YhGbsFhW8gEZDYvdtDQrVwmq7r2',
    // 'username': 'testepic3w2@gmail.com',
    // 'password': '123abc@',
    // 'scope': '*'
    username: username,
    password: password,
    code: code,
    token: token
  };
  // return JSON.stringify(params);
  return params;
}

export function userRegister(info) {
  const params = {
    username: info.username,
    password: info.password,
    password_confirmation: info.confirmPassword,
    email: info.email,
    language: info.language,
    // first_name: info.firstname,
    // last_name: info.lastname,
    hkid: info.hkid,
    // dob: info.dob,
    phone: info.mobile ?? '',
    kdp_member_phone_prefix: info.calling_code,
    kdp_member_phone: info.k11_mobile,
    kdp_member_number: info.member,
    phone_prefix:info.phone_prefix,
  }
  return params;
}

export function k11Recaptcha(info) {
  const params = {
    kdp_member_phone_prefix: info.kdp_member_phone_prefix,
    kdp_member_phone: info.kdp_member_phone,
  }
  return params;
}

export function k11Identity(info) {
  const params = {
    kdp_member_phone_prefix: info.kdp_member_phone_prefix,
    kdp_member_phone: info.kdp_member_phone,
    captcha: info.captcha,
  }
  return params;
}

export function bindMember(info) {
  const params = {
    kdp_member_phone_prefix: info.kdp_member_phone_prefix,
    kdp_member_phone: info.kdp_member_phone,
    kdp_member_number: info.kdp_member_number,
  }
  return params;
}

export function userVerifyCode({ token, code, type }) {
  const params = {
    // user_id: user_id,
    token: token,
    code: code,
    type: type
  }
  return params;
}

export function forgetPassword({ email }) {
  const params = {
    email: email
  }
  return params;
}

export function resetPassword({ email, password, password_confirmation, token }) {
  const params = {
    email: email,
    password: password,
    password_confirmation: password_confirmation,
    token: token
  }
  return params;
}

export function requestLoginCode({ username, password }) {
  const params = {
    password: password,
    username: username
  }
  return params;
}

export function resendVerifyCode({ token, type }) {
  const params = {
    token: token,
    type: type
  }
  return params;
}

export function activeAccount({ token, code }) {
  const params = {
    token: token,
    code: code
  }
  return params;
}

export function getPolicyDetail({ hkid }) {
  const params = {
    hkid: hkid,
  }
  return params;
}

export function getPolicyDoc({ hkid, policy_no }) {
  const params = {
    hkid: hkid,
    policy_no: policy_no
  }
  return params;
}

export function forgetUsername({ email }) {
  const params = {
    email: email
  }
  return params;
}

export function getK11Captcha({ prefix, phone }) {
  const params = {
    kdp_member_phone_prefix: '',
    kdp_member_phone: ''
  }
  return params;
}

export function verifyK11Identity({ prefix, phone, code }) {
  const params = {
    kdp_member_phone_prefix: '',
    kdp_member_phone: '',
    captcha: ''
  }
  return params;
}

export function bindK11Member({ prefix, phone, k11_member_number }) {
  const params = {
    kdp_member_phone_prefix: '',
    kdp_member_phone: '',
    kdp_member_number:''
  }
  return params;
}


export function getMissionList({ }) {
  const params = {

  }
  return params;
}

export function redeemMission({ mission_id, data }) {
  const params = {
    mission_id: mission_id,
    data:data
  }
  return params;
}


export function getReferralStatus({ }) {
  const params = {

  }
  return params;
}
export function getReferralCode({ }) {
  const params = {

  }
  return params;
}


export function updateUserInfo({ phone, dob }) {
  const params = {
    phone: phone,
    dob:dob
  }
  return params;
}

export function getClaimDetail({ hkid }) {
  const params = {
    hkid: hkid,
  }
  return params;
}
