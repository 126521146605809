import { Row, Col, Divider, Dropdown, Menu } from 'antd'
import LangButton from './ChangeLangButton';
import { PhoneFilled, UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";


const UserDropdown = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const userData = JSON.parse(localStorage.getItem('userData'));
  const { userinfo, username } = userData;
  // const { first_name, last_name, } = userinfo;

  if (window.innerWidth < 480) {
    return <Mobile_versions userData={userData} />
  }
  const menu = (
    <Menu style={{ width: 'auto', height: 'auto' }}>
      {/* <Menu.Item>
        <a onClick={() => { history.push('/profile') }}>
          {t('header.profile')}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={() => { history.push('/policy') }}>
          {t('header.policy')}
        </a>
      </Menu.Item> */}
      {/* <Menu.Item>
        <a onClick={() => { history.push('/home') }}>
          {t('header.logout')}
        </a>
      </Menu.Item> */}
      <MenuItem page={'/profile'} text={t('header.profile')} />
      <MenuItem page={'/home'} text={t('header.logout')} />
    </Menu>
  );
  return (
    <>
      <Dropdown overlay={menu} >
        <a style={{ color: '#4b4b4b' }} onClick={e => e.preventDefault()}>
          <UserOutlined />  {username}
        </a>
      </Dropdown>
    </>
  );
}
const MenuItem = ({ page, text }) => {
  const history = useHistory();

  return (
    <Menu.Item>
      <a onClick={() => { history.push(page) }}>
        {text}
      </a>
    </Menu.Item>
  )
}
const Mobile_versions = ({ userData }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { userinfo, username } = userData;

  return (
    <>
      <a className="buttonLink" onClick={() => { history.push('/profile') }} type="link">
        <UserOutlined />  {username}
      </a>
    </>
  )
}
export default UserDropdown;