import React, { useEffect } from 'react';
import './i18n.js';
import { useTranslation } from 'react-i18next';
// import LanguageList from './data/LanguageList.json';
import AppRoute from './core/AppRoute';

//redux
import reducer from './redux/Reducer';
import { createStore } from 'redux';
import { Provider } from "react-redux";
//redux persist
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react';
//Mystore from redux
import { store, persistor } from './redux/MyStore'

// const store = createStore(reducer);
// const LanguageKeys = Object.keys(LanguageList);

export default function App(props) {
  const { t, i18n } = useTranslation();

  // useEffect(() => {
  //   let language = i18n.language;
  //   if (!LanguageKeys.includes(language)) {
  //     language = 'en';
  //     i18n.changeLanguage(language);
  //   }
  // }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppRoute />
      </PersistGate>
    </Provider>
    // <Provider store={store}>
    //   <AppRoute />
    // </Provider>
  );
};
