import { Breadcrumb, Card } from 'antd';
import React, { Component, useEffect, useState } from 'react';
import _ from 'lodash';
import { Divider, Form, Input, Button, Checkbox, Row, Col, message, Spin, Modal, Popover, Table, Tag, Space } from 'antd';
import ConvertPDF, { DocButton } from './ConvertToPDF';
import { useTranslation, initReactI18next } from "react-i18next";

import ClaimItems from './ClaimItems'
import { convertStatus, convertPendingInfo } from './ConvertDataLang';

const ClaimDetail = () => {
  const { t, i18n } = useTranslation();

  const claimDataDetail = JSON.parse(localStorage.getItem('claim_detail')); // claimDataDetail = {...}
  // console.log(displayItem)
  const displayItem = ClaimItems();
  return (
    <>
      <DisplayData claimDataDetail={claimDataDetail} displayItem={displayItem} />
    </>
  )
}

const DisplayData = ({ claimDataDetail, displayItem }) => {
  // console.log('1', claimDataDetail)
  // console.log('2', displayItem)
  const { t, i18n } = useTranslation();

  const handle_data_lang = (data, itemVal) => {
    var temp_data = data;
    if (itemVal == 'status') { // channel status lang
      temp_data = convertStatus(data, t);
    } else if (itemVal == 'pending_info') { // payment pending-info lang
      temp_data = convertPendingInfo(data, t);
    }
    return temp_data;
  }


  return (
    <>
      <Card style={{ width: '100%', boxShadow: '3px 3px 9px #9BA3AA', borderRadius:10 }}>
        {/* <div style={{ width: divWidth(), margin: '0 auto', padding: '2%' }}> */}
        {
            _.map(displayItem, (item, index) => {

              return (
                <div key={index} style={{ margin: '3%' }}>
                  <Row className="policy_profile_display">
                    {/* <DisplayCol keyname={item.rename+':'} data={claimDataDetail[item.val]} num={index} /> */}
                    <DisplayCol keyname={item.rename + ':'} data={handle_data_lang(claimDataDetail[item.val], item.val)} num={index} />
                  </Row>
                </div>
  
              )
            })
        }

        {/* </div> */}
        {
          // <div style={{ margin: '3%' }}>
          //   <DocButton pol_no={claimDataDetail?.pol_no} />
          // </div>
        }

      </Card>
      {/* <Card style={{ width: '100%', boxShadow: '3px 3px 9px #9BA3AA', marginTop:'2%' }}>
        <div style={{ margin: '3%' }}>
          <DocButton pol_no={claimDataDetail?.pol_no} />
        </div>
      </Card> */}
    </>
  )


}
export const DisplayCol = ({ keyname, data, num }) => {
 
  return (
    <>
      <Col md={12} xs={24} style={{ textAlign: 'left' }} key={num + 'a'}>{keyname}</Col>
      <Col md={12} xs={24} style={{ textAlign: 'right',fontWeight:'unset' }} key={num + 'b'}>{data}</Col>
      <Divider />
    </>
  )
}


export default ClaimDetail;
