import { Row, Col, Divider, Dropdown, Button } from 'antd'
import LangButton from './ChangeLangButton';
import { PhoneFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
//user dropdown 
import UserDropdown from './UserDropdown';
// mobile components
import { Mobile_Header_indexBar,mobile_screen_size } from './MobileComponents';
//css
import "../styles.css";

const logo = '/images/logo.png';

const AppLayoutHeader = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const rightMenuAstyle={
    marginRight:15, display:'flex',justifyContent:'center',
    padding:'0px 5px'
  };

  const Right_side_menu = () => {
    return (
      <div style={{display:"flex",justifyContent:'flex-end',alignItems:'center'}}>
        {/* <a onClick={() => { history.push('/member-page') }} style={{ margin:'2%'}}>{t('header.home')}</a> */}
        {/* <Button className="buttonLink" onClick={() => { history.push('/profile') }} type="link" >{t('header.profile')}</Button> */}
        <a className="buttonLink" style={{...rightMenuAstyle}} onClick={() => { history.push('/dashboard') }} type="link">{t('header.home')}</a>
        <a className="buttonLink" style={{...rightMenuAstyle}} onClick={() => { history.push('/policy') }} type="link">{t('header.policy')}</a>
        {/* <a className="buttonLink" style={{...rightMenuAstyle}} onClick={() => { history.push('/mission') }} type="link">{t('header.mission')}</a> */}
        <a className="buttonLink" style={{...rightMenuAstyle}} onClick={() => { history.push('/claims') }} type="link">{t('header.claims')}</a>
        <UserDropdown />
        <Divider type="vertical" />
        <LangButton />
      </div>
    )
  }

  let modifyStyle = {}
  if(mobile_screen_size()){
    modifyStyle = {
      display:'flex',
      alignItems:'center',
      justifyContent:'flex-end'
    }
  }

  return (
    <div style={{ background: '#fff', padding: '1%', height: '10%', borderTop: '3px solid #862633', fontSize: 20 }}>
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        <div style={{ textAlign: 'left' }}>
          <a onClick={() => { history.push('/dashboard') }}><img src={logo} style={{ width: '300px', height: 'auto' }} /></a>
        </div>
        <div style={{ textAlign: 'right', padding: '1%', ...modifyStyle,}}>
          {
            mobile_screen_size() ?
              <Mobile_Header_indexBar />
              :
              <Right_side_menu />
          }
        </div>
      </div>
    </div>
  );
}


export default AppLayoutHeader;
