
import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';


// Translation folder
import en from './assets/i18n/en/translation.json';
import tw from './assets/i18n/tw/translation.json';



const DefaultLanguage = 'tw';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  tw:{
    translation: tw
  },
  en:{
    translation: en
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: DefaultLanguage, //set default language
    fallbackLng: DefaultLanguage,
    // debug: true,

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
