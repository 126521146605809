import { Layout, Menu, Divider, BackTop, Row, Col } from 'antd';
import { PhoneFilled } from '@ant-design/icons';
import LangButton from './ChangeLangButton';
import { useHistory } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";

const logo = '/images/logo.png';

export const HomeHeader = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  return (
    <>
      <div
        style={{
          // background: 'yellow',
          textAlign: 'center',
        }}
      >
        <Row>
          <Col md={12} style={{ textAlign: 'left' }}>
            <a onClick={() => { history.push('/home') }}><img src={logo} style={{ width: '300px', height: 'auto' }} /></a>
          </Col>
          <Col md={12} style={{ textAlign: 'right' }}>

            <LangButton type={'home'}/>
            <Divider type="vertical" />
            <a onClick={() => { history.push('/contactus') }}><PhoneFilled /></a>
          </Col>
        </Row>
      </div>
    </>
  );
}

export const HomeFooter = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div
        style={{
          // background: 'yellow',
          textAlign: 'right',
        }}
      >
        <p style={{ color: '#001976', fontSize: '10px' }}>{t('footer.all_right')}<br />{t('footer.all_right2')}</p>
        {/* <p style={{ color: '#001976' }}>{t('footer.all_right2')}</p> */}
      </div>
    </>
  );
}