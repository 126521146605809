import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  userLogin
} from '../core/HandleService';
//css
// import '../styles.css';


const TestingApi = () => {

  const testComponent = (title, method, data) => {
    return (
      <>
        <Button onClick={() => { method(data) }}>{title}</Button>
        <br />
      </>
    )
  }

  return (
    <div style={{ textAlign: 'center', lineHeight: 3 }}>
      <h1>Testing api page</h1>
      {/* <Button onClick={() => { updateCartItem({ cart_item_id: 215, quantity_of_log: 20 }) }}>update cart item testing</Button>
      <br /> */}
      {testComponent("login testing", userLogin, { username: 'oliver', password: '123' })}

    </div >
  );
};

export default TestingApi;
