import React, { Component, useEffect, useState } from 'react';
import AppLayout from '../AppLayout'
import { useTranslation, initReactI18next } from "react-i18next";
import { PageHeader } from 'antd';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Row, Col, message, Spin } from 'antd';
// form component
import { SubmitButtonItem, TextInputItem, EmailSentMsg } from './FormComponents'
//header page go back 
// import { GoBackPageHeader } from './PageHeader';

//call api
import { userVerifyCode, resendVerifyCode, activeAccount } from '../../core/HandleService';

function VerifyCodeForm({ data }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(true);
  const [resendLoading, setResendLoading] = useState(false);
  // console.log('data', data);
  const [resendToken, setResendToken] = useState();

  async function call_api({ code }) {
    const { token } = data; // token form register
    const params = {
      token: resendToken ?? token,
      code: code,
      type: 'register'
    }
    setLoading(true)
    // const params = { ...data, ...{ code: code }, ...{ type: 'register' } }
    const result = await userVerifyCode(params);
    if (result?.success) {
      // verifySuccess();
      await call_api_active_account(params)
    } else {
      message.error(result?.message)
    }
    setLoading(false)
    setEmailSent(false)
  }

  async function call_api_active_account(params) {
    const result = await activeAccount(params);
    if (result?.success) {
      // message.success(t('success'))
      verifySuccess();
    } else {
      message.error(result?.message)
    }
  }

  const resendCode = async () => {
    const { token } = data;
    const params = {
      token: token,
      type: 'register'
    }
    setResendLoading(true)
    setEmailSent(false)
    // const params = { user_id: data?.user_id, type: 'register' }
    const result = await resendVerifyCode(params);
    if (result?.success) {
      message.success(t('loginForm.check_email'));
      setEmailSent(true)
      setResendToken(result?.data?.token)
    } else {
      message.error(result?.message)
    }
    setResendLoading(false)
  }

  const onFinish = (values) => {
    call_api(values)
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const verifySuccess = () => {
    message.success(t('registerForm.success'));
    setTimeout(() => {
      history.push('/login');
    }, 2000)
  }

  return (
    <Spin spinning={loading}>
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{ width: '100%', padding: '10%' }}
      >
        <h1>{t('verify.title')}</h1>

        <Input.Group compact>
          <TextInputItem nameKey='code' placeholder={t('verify.verify_code')} msg={t('verify.msg')} />
          <Button loading={resendLoading} onClick={resendCode}>{t('verify.resend_verify_code')}</Button>
        </Input.Group>
        {
          emailSent ?
            <EmailSentMsg ScreenWidth={window.innerWidth} alignPos='center'/>
          :
          null
        }
        <SubmitButtonItem name={t('button.submit')} />

      </Form>
    </Spin>
  )
}
export default VerifyCodeForm;