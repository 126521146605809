import React, { Component, useEffect, useState } from 'react';
import AppLayout from '../AppLayout'
import { useTranslation, initReactI18next } from "react-i18next";
import { PageHeader } from 'antd';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Row, Col, message, Spin } from 'antd';
// form component
import { EmailInputItem, ButtonItem, SubmitButtonItem, TextInputItem, SelectDateItem, ConfirmPasswordInputItem, PasswordInputItem, SelectCallingCodeItem, TextInputItem_Optional, FunctionalInputItem, PasswordInputItem_register } from './FormComponents'
//header page go back 
// import { GoBackPageHeader } from './PageHeader';
import { k11Recaptcha, userRegister, k11Identity, getK11Captcha } from '../../core/HandleService'
import getCurrentLanguage from '../GetCurrentLanguage'

function RegisterForm() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [showRecaptchaIuput, setShowRecaptchaIuput] = useState(true)
  const [phonePrefix,setPhonePrefix] = useState('');
  const [k11_member_phone, setK11_member_phone] = useState();
  // const [k11_calling_code, setK11_calling_code] = useState();
  const [callingCode, setCallingCode] = useState('')
  const [k11Mobile, setK11Mobile] = useState('')
  const [recaptcha, setRecaptcha] = useState('')
  const [form] = Form.useForm();

  const onFinish = (values) => {
    var currentLang = getCurrentLanguage();
    const data = { ...values, ... { language: currentLang },...{phone_prefix:phonePrefix} }
    // console.log('data:', data);
    // return;
    // call api
    call_api(data)
  };

  async function call_api(data) {
    setLoading(true)
    const result = await userRegister(data);
    // console.log('register: ', result);
    if (result?.success) {
      registerSuccess(result?.data);
    } else {
      message.error(result?.message)
    }
    setLoading(false)
  }
  async function call_api_getK11Captcha(data) {
    setLoading(true)
    const result = await getK11Captcha(data);
    // console.log('register: ', result);
    if (result?.success) {
      // enable the verify input for user 
      setShowRecaptchaIuput(false);
    } else {
      message.error(result?.message)
    }
    setLoading(false)
  }

  async function get_recaptcha() {
    setLoading(true)
    const data = {
      kdp_member_phone_prefix: callingCode,
      kdp_member_phone: k11Mobile,
    }
    const result = await k11Recaptcha(data);
    if (result?.success) {
      setShowRecaptchaIuput(false);
      message.success(t('registerForm.check_sms'))
    } else {
      message.error(result?.message)
    }
    setLoading(false)
  }

  async function verify() {
    setLoading(true)
    const data = {
      kdp_member_phone_prefix: callingCode,
      kdp_member_phone: k11Mobile,
      captcha: recaptcha
    }
    const result = await k11Identity(data);
    if (result?.success) {
      console.log(result?.data.kdp_member_number)
      form.setFieldsValue({
        member: result?.data.kdp_member_number,
      })
    } else {
      message.error(t(`kdpVerifyIdentity.${result?.debug_message.errorcode}`));
    }
    setLoading(false)
  }

  const registerSuccess = (data) => {
    // message.success("success , please check email and input the verify code in next page.");
    message.success(t('registerForm.check_email'))
    setTimeout(() => {
      history.push({
        pathname: '/verify',
        register: true,
        data: data,
      })
    }, 2000);
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onInputChange = (changedValues) => {
    const fieldKey = Object.keys(changedValues)[0];

    if (fieldKey === 'k11_mobile' && !!changedValues[fieldKey]) {
      setShowRecaptchaIuput(false)
    } else if (fieldKey === 'k11_mobile' && !changedValues[fieldKey]) {
      setShowRecaptchaIuput(true)
    }
  }
  const phone_prefix_onchange = (e)=>{
    const phone_prefix = e;
    setPhonePrefix(phone_prefix)
  }

  // const click_getCaptcha = () => {
  //   const temp_params = {
  //     phone: k11_member_phone,
  //     prefix: k11_calling_code
  //   }
  //   // call api ...
  //   call_api_getK11Captcha(temp_params)
  // }
  const k11_member_phone_onchange = (e) => {
    const phone = e.target.value;
    setK11Mobile(phone)
  }
  const k11_calling_code_onchange = (e) => {
    const calling_code = e;
    setCallingCode(calling_code)
  }
  const k11_captcha_onchange = (e) => {
    const captcha = e.target.value;
    console.log(captcha)
    setRecaptcha(captcha)
  }

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        name="basic"
        initialValues={{
          remember: true,
        }}
        // onValuesChange={onInputChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{ width: '100%', padding: '10%' }}
      >
        <h1>{t('registerForm.title')}</h1>
        <TextInputItem nameKey='username' placeholder={t('registerForm.username')} msg={t('registerForm.username_msg')} />

        <EmailInputItem nameKey='email' placeholder={t('registerForm.email')} msg={t('registerForm.email_msg')} />

        <Row>
          <Col span={5}>
            <SelectCallingCodeItem nameKey='phone_prefix' placeholder={t('registerForm.calling_code')}
            onChange={phone_prefix_onchange}
            rules={[ { required: true, message: ' ', },]}
            />
          </Col>
          <Col span={19}>
            <TextInputItem nameKey='mobile' placeholder={t('registerForm.mobile')} msg={t('registerForm.mobile_msg')} />
          </Col>
        </Row>
        {/* <TextInputItem nameKey='firstname' placeholder={t('registerForm.firstName')} msg={t('registerForm.firstName_msg')} /> */}

        {/* <TextInputItem nameKey='lastname' placeholder={t('registerForm.lastName')} msg={t('registerForm.lastName_msg')} /> */}

        <SelectDateItem nameKey='dob' placeholder={t('registerForm.dob')} msg={t('registerForm.dob_msg')} required_false={true}/>

        <TextInputItem nameKey='hkid' placeholder={t('registerForm.hkid')} msg={t('registerForm.hkid_msg')} />

        {/* <Row>
          <Col span={5}>
            <SelectCallingCodeItem nameKey='calling_code' placeholder={t('registerForm.calling_code')} onChange={k11_calling_code_onchange} />
          </Col>
          <Col span={14} >
            <TextInputItem_Optional nameKey='k11_mobile' placeholder={t('registerForm.k11_mobile')} onChange={k11_member_phone_onchange} />
          </Col>
          <Col span={5}>
            <ButtonItem name={t('registerForm.getCaptcha')} onClick={get_recaptcha} /> 
          </Col>
        </Row>

        <Row>
          <Col span={19}>
            <FunctionalInputItem nameKey='recaptcha' placeholder={t('verify.verify_code')} hidden={showRecaptchaIuput} onChange={k11_captcha_onchange} required_false={true}></FunctionalInputItem>
          </Col>
          <Col span={5}>
            <ButtonItem name={t('verify.title')} hidden={showRecaptchaIuput} onClick={verify}/> 
          </Col>
        </Row>
        
        <FunctionalInputItem nameKey='member' placeholder={t('registerForm.member')} disabled='true' required_false={true}></FunctionalInputItem> */}
        
        <PasswordInputItem_register nameKey='password' placeholder={t('registerForm.password')} msg={t('registerForm.password_msg')} password_policy={t('registerForm.password_policy')} />

        <ConfirmPasswordInputItem nameKey='confirmPassword' placeholder={t('registerForm.confirm_password')}
          msg={t('registerForm.confirmPassword_msg_notMatch')} dependencies='password' notMatch={t('registerForm.confirmPassword_msg_notMatch')} />

        <SubmitButtonItem name={t('forgetPasswordForm.submit')} />

      </Form>
    </Spin>
  )
}
export default RegisterForm;