import React from 'react';
import AppLayoutHeader from './AppLayoutHeader';
import AppLayoutFooter from './AppLayoutFooter';
import AppLayoutSider from './AppLayoutSider';
import LangButton from './ChangeLangButton'
import { Layout, Menu, Divider, BackTop, Row, Col } from 'antd';
import { PhoneFilled } from '@ant-design/icons';
import { HomeHeader, HomeFooter } from './HomeHeaderAndFooter';
// mobile 
import { mobile_screen_size, Mobile_Login_Page_display } from './MobileComponents'

const homePhoto = '/images/homePhoto2.png';

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

const minHeightValue = '100vh';


class HomeAppLayout extends React.Component {

  render() {
    const {
      children,
      noSidebar,
      title,
      contentCenter
    } = this.props;
    // if (mobile_screen_size()) {
    if (window.innerWidth < 480) {
      return (
        <Mobile_Login_Page_display
          conent={<Content>{children}</Content>}
          homePhoto={homePhoto}
          homeHeader={<HomeHeader />}
          homeFooter={<HomeFooter />}
        />
      )
    }
    return (
      <Layout style={{
        minHeight: minHeightValue,
        // maxWidth: '1000px',
        margin: 'auto',
        background: 'white'
      }}>
        {/* <AppLayoutHeader /> */}

        <Content>
          <Row style={{ textAlign: 'center', minHeight: minHeightValue }}>
            <Col md={8} xs={24}>
              <img src={homePhoto} style={{ width: '100%', height: '100%' }} />
            </Col>
            <Col md={16} xs={24}>
              <Layout style={{ padding: '2%', height: '100%' }}>
                <HomeHeader />
                <Content>
                  {
                    contentCenter === true ? (
                      <Row justify="center" align="middle" style={{ minHeight: '55vh' }}>
                        {children}
                      </Row>
                    ) : children
                  }
                </Content>
                <HomeFooter />
              </Layout>
            </Col>
          </Row>
        </Content>

        {/* <AppLayoutFooter /> */}
      </Layout>
    );
  }
}
export default HomeAppLayout;