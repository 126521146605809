import React, { Component, useEffect, useState } from 'react';
import AppLayout from '../AppLayout'
import { useTranslation, initReactI18next } from "react-i18next";
import { PageHeader } from 'antd';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Row, Col, message, Spin } from 'antd';
// form component
import { EmailInputItem, SubmitButtonItem, TickMsg } from './FormComponents'
//header page go back
// import { GoBackPageHeader } from './PageHeader';

//call api
import { forgetPassword } from '../../core/HandleService.js';

function ForgetPasswordForm() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  async function call_api(data) {
    setLoading(true);
    setEmailSent(false)
    const result = await forgetPassword(data);
    if (result?.success) {
      // message.success('send success, please check your email')
      message.success(t('forgetPasswordForm.check_email'))
      setEmailSent(true)
    } else {
      message.error(result?.message)
    }
    setLoading(false);
  }

  const onFinish = (values) => {
    // console.log('Success:', values);
    // message.success('send success')
    call_api(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Spin spinning={loading}>
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{ width: '100%', padding: '10%' }}
      >
        <h1>{t('forgetPasswordForm.title')}</h1>

        <EmailInputItem nameKey='email' placeholder={t('forgetPasswordForm.email')} msg={t('forgetPasswordForm.msg')} />
        {
           emailSent ?
            <TickMsg msg={t('forgetPasswordForm.check_email')}/>
            :
            null
        }
        <SubmitButtonItem name={t('forgetPasswordForm.submit')} />

      </Form>
    </Spin>
  )
}
export default ForgetPasswordForm;