import React, { Component, useEffect, useState } from 'react';
import { Layout, Button, Row, Col, Divider, Card, Breadcrumb } from 'antd';
import { useTranslation, initReactI18next } from "react-i18next";
// import * as ServiceCall from '../core/ServiceCall';
import { userLogin } from "../core/HandleService";
import LoginForm from '../component/form/LoginForm';
import LangButton from '../component/ChangeLangButton'
import { PhoneFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import AppLayout from '../component/AppLayout'
import HomeAppLayout from '../component/HomeAppLayout'
//display 
import ProfileDisplay from '../component/ProfileDisplay'
// go back 
import { GobackPage } from '../component/PageHeader'
import { policy_title_style } from './UserPolicyPage';

function ProfilePage() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem('userData'));
  const { userinfo } = userData;

  const [ScreenSize,setScreenSize]=useState();
  const resize=()=>{
    setScreenSize({
      width:window.innerWidth,
      height:window.innerHeight,
    })
  }
  useEffect(() => {
    window.addEventListener("resize", resize)
  }, []);

  const Display_breadcrumb = () => {
    return (
      <div className="policy_profile_display">
        <div style={{ padding: '1%', color: 'rgb(145 145 145)' }}>{t('header.profile')}</div>
        {/* <Breadcrumb separator=">" style={{ padding: '1%' }} >
          <Breadcrumb.Item >{t('header.profile')}</Breadcrumb.Item>
        </Breadcrumb> */}
      </div>
    )
  }

  return (
    <AppLayout>
      <Display_breadcrumb />
      <div style={{ margin: '0 auto', padding: '2% 10% 2% 10%' }}>
        {/* <button disabled style={policy_title_style}>{t('header.profile')}</button> */}
        <Card style={{ width: '100%', boxShadow: '3px 3px 9px #9BA3AA',borderRadius:10 }}>
          <ProfileDisplay />
        </Card>
      </div>
    </AppLayout>
  )

}

export default ProfilePage;
