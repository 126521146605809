import { Form, Input, Button, Checkbox, Row, Col, DatePicker, InputNumber, Select } from 'antd';
import { useTranslation, initReactI18next } from "react-i18next";

const kdpTickLogo = '/images/kdp_tick_1.png';
// export function textInputItem(nameKey, placeholder, msg) {
//   return (
//     <Form.Item
//       name={nameKey}
//       rules={[
//         {
//           required: true,
//           message: msg,
//         },
//       ]}
//     >
//       <Input placeholder={placeholder} />
//     </Form.Item>
//   )
// }
export function TextInputItem({ nameKey, placeholder, msg }) {
  return (
    <Form.Item
      name={nameKey}
      rules={[
        {
          required: true,
          message: msg,
        },
      ]}
    >
      <Input placeholder={placeholder} />
    </Form.Item>
  )
}
export function PasswordInputItem({ nameKey, placeholder, msg }) {
  return (
    <Form.Item
      name={nameKey}
      rules={[
        {
          required: true,
          message: msg,
        },
      ]}
    >
      <Input.Password placeholder={placeholder} />
    </Form.Item>
  )
}
export function ConfirmPasswordInputItem({ nameKey, placeholder, msg, dependencies, notMatch }) {
  return (
    <Form.Item
      name={nameKey}
      // label="Confirm Password"
      dependencies={[dependencies]}
      hasFeedback
      rules={[
        {
          required: true,
          message: msg,
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue(dependencies) === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error(notMatch));
          },
        }),
      ]}
    >
      <Input.Password placeholder={placeholder} />
    </Form.Item>
  )
}

export function ButtonItem({ name, hidden, onClick }) {
  return (
    <Form.Item
      hidden={hidden}
    >
      <Button type="primary" style={{ width: '101%' }} onClick={onClick}>
        {name}
      </Button>
    </Form.Item>
  )
}

export function SubmitButtonItem({ name,style }) {
  return (
    <Form.Item>
      <Button type="primary" htmlType="submit" style={{ width: '40%',...style }}>
        {name}
      </Button>
    </Form.Item>
  )
}
export function EmailInputItem({ nameKey, placeholder, msg }) {
  return (
    <Form.Item
      name={nameKey}
      rules={[
        {
          type: 'email',
          required: true,
          message: msg,
        },
      ]}
    >
      <Input placeholder={placeholder} />
    </Form.Item>
  )
}
export function SelectDateItem({ nameKey, placeholder, msg, required_false}) {
  return (
    <Form.Item
      name={nameKey}
      rules={[
        {
          required: required_false ? false : true,
          message: msg,
        },
      ]}
    >
      <DatePicker placeholder={placeholder} style={{ width: '100%' }} />
    </Form.Item>
  )
}

export function SelectCallingCodeItem({ nameKey, placeholder, onChange , rules}) {
  const { Option } = Select;
  return (
    <Form.Item
      name={nameKey}
      rules={rules}
    >
      <Select placeholder={placeholder} onChange={onChange}>
        <Option value="852">+852</Option>
        <Option value="86">+86</Option>
      </Select>
    </Form.Item>
  )
}

export function TextInputItem_Optional({ nameKey, placeholder, onChange }) {
  return (
    <Form.Item
      name={nameKey}
    >
      <Input placeholder={placeholder} style={{ textAlign: 'center' }} onChange={onChange} />
    </Form.Item>
  )
}

export function FunctionalInputItem({ nameKey, placeholder, msg, hidden, disabled, onChange, required_false }) {
  return (
    <Form.Item
      name={nameKey}
      hidden={hidden}
      rules={[
        {
          required: required_false ? false : true,
          message: msg,
        },
      ]}
    >
      <Input placeholder={placeholder} disabled={disabled} style={{ textAlign: 'center' }} onChange={onChange} />
    </Form.Item>
  )
}

export function PasswordInputItem_register({ nameKey, placeholder, msg, password_policy }) {
  // const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[!@#\$%\^&\*])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])))(?=.{6,})");
  const mediumRegex = new RegExp("^((?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.*[0-9]))");

  return (
    <Form.Item
      name={nameKey}
      rules={[
        {
          required: true,
          message: msg,
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            const formatPwd = (mediumRegex.test(value) && value.length >= 8)
            if (formatPwd) {
              return Promise.resolve();
            }
            return Promise.reject(password_policy);

          },
        }),
      ]}

    >
      <Input.Password placeholder={placeholder} />
    </Form.Item>
  )
}

export function EmailSentMsg({ alignPos = 'left' ,ScreenWidth}) {
  const { t, i18n } = useTranslation();
  return (
      <Row style={{
        justifyContent:alignPos,
      }}>
          <div style={{
            display:'flex',
            marginBottom: '15px',
            textAlign: alignPos
          }}>
              <div style={{}}>
              <img src={kdpTickLogo} style={{ width: '25px',marginRight: '10px'}} />
              </div>

              {
                ScreenWidth <= 480 &&
                <div style={{
                  display:'flex',
                  flexDirection:'column',
                }}>
                <div style={{}}>{t('loginForm.check_email_1')}</div>
                <div style={{}}>{t('loginForm.check_email_2')}</div>
                </div>
              }
              {
                ScreenWidth>480 &&
                <div style={{
                  // textAlign:'left'
                }}>
                {t('loginForm.check_email_1')}
                {t('loginForm.check_email_2')}
                </div>
              }

          </div>
      </Row>
  )
}

export function VerifyForgotUsernameSuccessMsg({ alignPos = 'left' }) {
  const { t, i18n } = useTranslation();
  return (
      <Row>
          <Col md={24}>
              <div style={{textAlign: alignPos, marginBottom: '15px'}}>
              <img src={kdpTickLogo} style={{ width: '25px',marginRight: '10px'}} />
              <div style={{border:'solid 1px'}}>
              {t('forGotUsername.verifySuccess')}
              </div>
              </div>
          </Col>
      </Row>
  )
}

export function TickMsg({ alignPos = 'left' , msg}) {
  const { t, i18n } = useTranslation();
  return (
      <Row>
          <Col md={24}>
              <div style={{textAlign: alignPos, marginBottom: '15px'}}>
              <img src={kdpTickLogo} style={{ width: '25px',marginRight: '10px'}} />
              {msg}
              </div>
          </Col>
      </Row>
  )
}
