// handle params
import * as handleParams from './HandleParams'
// api call
import * as ServiceCall from './ServiceCall';

export async function userLogin(info) {
  // const data = JSON.stringify({});
  try {
    const data = handleParams.userLogin(info)
    const result = await ServiceCall.userLogin(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}
export async function userRegister(info) {
  try {
    const data = handleParams.userRegister(info)
    const result = await ServiceCall.userRegister(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function k11Recaptcha(info) {
  try {
    const data = handleParams.k11Recaptcha(info)
    const result = await ServiceCall.k11Recaptcha(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function k11Identity(info) {
  try {
    const data = handleParams.k11Identity(info)
    const result = await ServiceCall.k11Identity(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function bindMember(info) {
  try {
    const data = handleParams.bindMember(info)
    const result = await ServiceCall.bindMember(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function userVerifyCode(info) {
  try {
    const data = handleParams.userVerifyCode(info)
    const result = await ServiceCall.userVerifyCode(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function forgetPassword(info) {
  try {
    const data = handleParams.forgetPassword(info)
    const result = await ServiceCall.forgetPassword(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function resetPassword(info) {
  try {
    const data = handleParams.resetPassword(info)
    const result = await ServiceCall.resetPassword(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function requestLoginCode(info) {
  try {
    const data = handleParams.requestLoginCode(info)
    const result = await ServiceCall.requestLoginCode(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function userLogout(info) {
  try {
    const result = await ServiceCall.userLogout()
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function resendVerifyCode(info) {
  try {
    const data = handleParams.resendVerifyCode(info)
    const result = await ServiceCall.resendVerifyCode(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function activeAccount(info) {
  try {
    const data = handleParams.activeAccount(info)
    const result = await ServiceCall.activeAccount(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function getPolicyDetail(info) {
  try {
    const data = handleParams.getPolicyDetail(info)
    const result = await ServiceCall.getPolicyDetail(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function getPolicyDoc(info) {
  try {
    const data = handleParams.getPolicyDoc(info)
    const result = await ServiceCall.getPolicyDoc(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function forgetUsername(info) {
  try {
    const data = handleParams.forgetUsername(info)
    const result = await ServiceCall.forgetUsername(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function getK11Captcha(info) {
  try {
    const data = handleParams.getK11Captcha(info)
    const result = await ServiceCall.getK11Captcha(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function verifyK11Identity(info) {
  try {
    const data = handleParams.verifyK11Identity(info)
    const result = await ServiceCall.verifyK11Identity(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function bindK11Member(info) {
  try {
    const data = handleParams.bindK11Member(info)
    const result = await ServiceCall.bindK11Member(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function getMissionList(info) {
  try {
    const data = handleParams.getMissionList(info)
    const result = await ServiceCall.getMissionList(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function redeemMission(info) {
  try {
    const data = handleParams.redeemMission(info)
    const result = await ServiceCall.redeemMission(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}


export async function getReferralStatus(info) {
  try {
    const data = handleParams.getReferralStatus(info)
    const result = await ServiceCall.getReferralStatus(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function getReferralCode(info) {
  try {
    const data = handleParams.getReferralCode(info)
    const result = await ServiceCall.getReferralCode(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}


export async function updateUserInfo(info) {
  try {
    const data = handleParams.updateUserInfo(info)
    const result = await ServiceCall.updateUserInfo(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}

export async function getClaimDetail(info) {
  try {
    const data = handleParams.getClaimDetail(info)
    const result = await ServiceCall.getClaimDetail(data)
    return result.data;
  } catch (error) {
    return { 'error': error }
    // console.error('error', err)
  }
}
