import React, { Component, useEffect, useState } from 'react';
// import AppLayout from '../component/AppLayout'
import HomeAppLayout from '../component/HomeAppLayout'
import { useTranslation, initReactI18next } from "react-i18next";
import { PageHeader } from 'antd';
import ResetPasswordForm from '../component/form/ResetPasswordForm';
//header page go back 
// import { GoBackPageHeader } from '../component/PageHeader';
import { useHistory, useLocation } from 'react-router-dom';


function ResetPasswordPage(props) {
  const { t, i18n } = useTranslation();

  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get('token');
  const email = query.get('email');

  return (
    <HomeAppLayout>
      {/* <GoBackPageHeader/> */}
      <ResetPasswordForm token={token} email={email} />
    </HomeAppLayout>
  )
}
export default ResetPasswordPage;