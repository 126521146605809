import React, { Component, useEffect, useState } from 'react';
import { Layout, Button, Row, Col, Divider } from 'antd';
import { useTranslation, initReactI18next } from "react-i18next";
// import * as ServiceCall from '../core/ServiceCall';
import { userLogin } from "../core/HandleService";
import LoginForm from '../component/form/LoginForm';
import LangButton from '../component/ChangeLangButton'
import { PhoneFilled } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
// import AppLayout from '../component/AppLayout'
import HomeAppLayout from '../component/HomeAppLayout'
import localStorageClear from '../component/LocalStorageClear'

function HomePage() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { lang } = useParams();

  const [ScreenSize,setScreenSize]=useState();
  const resize=()=>{
    setScreenSize({
      width:window.innerWidth,
      height:window.innerHeight,
    })
  }

  useEffect(() => {
    let toSetLang = lang;
    if (lang === 'en'){
      toSetLang = 'en';
    }else if (lang === 'ch'){
      toSetLang = 'tw';
    }else{
      return;
    }
    localStorage.setItem('i18nextLng', toSetLang);
    i18n.changeLanguage(toSetLang);
  }, [lang]);

  useEffect(() => {
    window.addEventListener("resize", resize)
    //clear localStorage
    localStorageClear()
  }, []);
  return (
    <HomeAppLayout>
      {/* <Row>
        <Col md={12} style={{ textAlign: 'left' }}>
          logo
        </Col>
        <Col md={12} style={{ textAlign: 'right' }}>
          <LangButton />
          <Divider type="vertical" />
          <a><PhoneFilled /></a>
        </Col>
      </Row> */}
      <LoginForm />
    </HomeAppLayout>
  )
  // return (
  //   <AppLayout>
  //     <Row style={{ textAlign: 'center' }}>
  //       <Col md={12}>photo</Col>
  //       <Col md={12} style={{ padding: '2%' }}>
  //         <Row>
  //           <Col md={12} style={{ textAlign: 'left' }}>
  //             logo
  //           </Col>
  //           <Col md={12} style={{ textAlign: 'right' }}>
  //             <LangButton />
  //             <Divider type="vertical" />
  //             <a><PhoneFilled /></a>
  //           </Col>
  //         </Row>
  //         <LoginForm />
  //       </Col>
  //     </Row>
  //   </AppLayout>
  // );
}

export default HomePage;
