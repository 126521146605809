import React, { Component, useEffect, useState } from 'react';
import { Button, Menu, Dropdown } from "antd";
import { useTranslation, initReactI18next } from "react-i18next";

const ChangeLangButton = ({ setVisible, type }) => {
  const { t, i18n } = useTranslation();

  const handleOnClick = (lang) => {
    // setLang(lang);
    localStorage.setItem('i18nextLng', lang);
    i18n.changeLanguage(lang);
  }


  if (window.innerWidth < 480 && type=='index_bar') {
    return <Mobile_versions setVisible={setVisible} />
  }
  let extraStyle={}
  if( type =='menu_lang' ){
    return (
      <div style={{
        padding : '0px 10px',
        border : 'solid 1px #b4b4b4',
        display :'flex',
        flexDirection : 'row',
        alignItems : 'center',
      }}>
      <a className="buttonLink" onClick={() => handleOnClick('en')} type="link">EN </a>
      <div style={{height:14,width:1,backgroundColor:'#4b4b4b',margin:'0px 5px'}}/>
      <a className="buttonLink" onClick={() => handleOnClick('tw')} type="link">繁</a>
      </div>
    )

  }
  else if (type=="home") {
    return(
      <>
        <Button onClick={() => handleOnClick('en')} type="link">EN</Button>
        <Button onClick={() => handleOnClick('tw')} type="link">繁</Button>
      </>
    )
  }
  
  return (
    <>
      {
        localStorage.getItem('accessToken')
          ?
          <>
            <a className="buttonLink" onClick={() => handleOnClick('en')} type="link">EN </a>
            <div style={{width:10}}/>
            <a className="buttonLink" onClick={() => handleOnClick('tw')} type="link">繁</a>
          </>
          :
          <>
            <Button onClick={() => handleOnClick('en')} type="link">EN</Button>
            <Button onClick={() => handleOnClick('tw')} type="link">繁</Button>
          </>
      }
    </>
  )
}



const Mobile_versions = ({ setVisible }) => {
  const { t, i18n } = useTranslation();
  const handleOnClick = (lang) => {
    // setLang(lang);
    localStorage.setItem('i18nextLng', lang);
    i18n.changeLanguage(lang);
    setTimeout(() => {
      setVisible(false);
    }, 500)
  }
  const MenuItem_content = ({ lang, text }) => {
    return (
      <>
        <a onClick={() => { handleOnClick(lang) }} >
          {text}
        </a>
      </>
    )
  }
  const menu = (
    <Menu style={{ width: 'auto', height: 'auto' }} >
      <Menu.Item key={'en'}>
        <MenuItem_content lang={'en'} text={'EN'} />
      </Menu.Item>
      <Menu.Item key={'tw'}>
        <MenuItem_content lang={'tw'} text={'繁'} />
      </Menu.Item>
    </Menu>

  );

  return (
    <>
      <Dropdown overlay={menu} >
        <a style={{ color: '#4b4b4b' }} onClick={e => e.preventDefault()}>
          {t('lang')}
        </a>
      </Dropdown>
    </>
  );

}

export default ChangeLangButton;
