import React, { Component, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Form, Input, Button, Checkbox, Row, Col, message, Spin } from 'antd';
import { useTranslation, initReactI18next } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { userLogin, requestLoginCode } from '../../core/HandleService';

// form component
import { TextInputItem, PasswordInputItem, SubmitButtonItem, EmailSentMsg } from './FormComponents'
import getCurrentLanguage from '../GetCurrentLanguage'

export default function LoginForm() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [requestCode, setRequestCode] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tempAccount, setTempAccount] = useState();
  const [responseData, setResponseData] = useState({});

  async function call_api_login(data) {
    setLoading(true)
    const result = await userLogin({ ...data, ...responseData });
    if (result?.success) {
      // console.log('access_token', result?.data?.access_token)
      localStorage.setItem('accessToken', result?.data?.access_token) // store accessToken
      // console.log('userData', result?.data?.user)
      localStorage.setItem('userData', JSON.stringify(result?.data?.user)) // store user information
      message.success(t('loginForm.success'));
      setTimeout(() => {
        // history.push('/policy')
        history.push('/dashboard')
      }, 2000);
    } else {
      message.error('login error')
    }
    setLoading(false)
    setEmailSent(false)
  }

  async function call_api_requestCode(data) {
    setLoading(true)
    setEmailSent(false)
    const result = await requestLoginCode(data);
    if (result?.success) {
      message.success(t('loginForm.check_email'))
      setResponseData(result?.data)
      setRequestCode(true)
      setEmailSent(true)
    } else {
      message.error(result?.message)
    }
    setLoading(false)
  }

  const onFinish = (values) => {
    if (requestCode) {
      call_api_login(values)
    } else {
      setTempAccount(values);
      call_api_requestCode(values)
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  return (
    <Spin spinning={loading}>
      <Form
        name="basic"
        // labelCol={{
        //   span: 8,
        // }}
        // wrapperCol={{
        //   span: 16,
        // }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{ width: '100%', padding: '10%' }}
      >
        <h1>{t('loginForm.title')}</h1>
        <TextInputItem nameKey='username' placeholder={t('loginForm.username')} msg={t('loginForm.msgUsername')} />

        <PasswordInputItem nameKey='password' placeholder={t('loginForm.password')} msg={t('loginForm.msgPassword')} />

        {
          requestCode ?
            <Row>
              <Col md={18} xs={12}>
                <TextInputItem nameKey='code' placeholder={t('verify.verify_code')} msg={t('verify.msg')} />
              </Col>
              <Col md={6} xs={12}>
                <Button onClick={() => { call_api_requestCode(tempAccount) }}>{t('verify.resend_verify_code')}</Button>
              </Col>
            </Row>

            :
            ''
        }
        {
          emailSent ?
            <EmailSentMsg ScreenWidth={window.innerWidth} />
          :
          null
        }

        <SubmitButtonItem name={t('loginForm.submit')} />

        <Row style={{ width: '100%' }}>
          <Col span={24} style={{ textAlign: 'left' }}><a onClick={() => { history.push('/forget-name') }}>{t('loginForm.forgetUsername')}</a></Col>
          <Col span={24} style={{ textAlign: 'left' }}><a onClick={() => { history.push('/forget') }}>{t('loginForm.forgetPassword')}</a></Col>
        </Row>
        <br />
        <div style={{
          display:'flex',
          justifyContent:'space-between',
        }}>
          {
            window.innerWidth < 520?
            <div>
              <div style={{ textAlign: 'left' }}>
                {t('loginForm.member')}
              </div>
              <div style={{ textAlign: 'left' }}>
                <a href={`${process.env.REACT_APP_API_BASE_URL}/storage/concord_portal_turtorial_${getCurrentLanguage()}.pdf`} target="_blank">({t('registerForm.tutorial')})</a>
              </div>
            </div>
            :
            <div>
              <div style={{ textAlign: 'left' }}>
                {t('loginForm.member')} <a href={`${process.env.REACT_APP_API_BASE_URL}/storage/concord_portal_turtorial_${getCurrentLanguage()}.pdf`} target="_blank">({t('registerForm.tutorial')})</a>
              </div>
            </div>
          }

          <div>
            <div style={{ textAlign: 'right' }}>
              <a onClick={() => { history.push('/register') }}>{t('loginForm.register')}</a>
            </div>
          </div>
        </div>
      </Form >
    </Spin>

  );
};
