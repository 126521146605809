import React, { Component, useEffect, useState } from 'react';
// import AppLayout from '../component/AppLayout'
import HomeAppLayout from '../component/HomeAppLayout'
import { useTranslation, initReactI18next } from "react-i18next";
import { PageHeader } from 'antd';

import ForgetUsernameForm from '../component/form/ForgetUsernameForm';
//header page go back 
import { GoBackPageHeader } from '../component/PageHeader';

function ForgetUsernamePage() {
  const { t, i18n } = useTranslation();

  return (
    <HomeAppLayout>
      <GoBackPageHeader/>
      <ForgetUsernameForm/>
    </HomeAppLayout>
  )
}
export default ForgetUsernamePage;