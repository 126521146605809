import * as type from './ActionType';
import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'


const initState = {
  todoList: ['first', 'second'],
};



function handleList(state = initState.todoList, action) {
  switch (action.type) {
    case type.ADD_TODOLIST:
      return [...state, action.item];
    default: return state;
  }
}

function handleUserName(state = '', action) {
  switch (action.type) {
    case type.SET_USER:
      return action.userName;
    default: return state;
  }
}
const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_ALL') {
      // for all keys defined in your persistConfig(s)
      storage.removeItem('persist:root')
      // storage.removeItem('persist:otherKey')

      return reducer(undefined, action);
  }
  return reducer(state, action);
};

const reducer = combineReducers(
  {
    userName: handleUserName,
    list: handleList,
  }
)
export default rootReducer;