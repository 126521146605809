import { useTranslation, initReactI18next } from "react-i18next";


const ClaimItems = () => {
  const { t, i18n } = useTranslation();

  const displayItem = [
    { val: 'refno', rename: t('claim_display.refno') },
    { val: 'polno', rename: t('claim_display.polno') },
    { val: 'holder', rename: t('claim_display.holder') },
    { val: 'insured', rename: t('claim_display.insured') },
    { val: 'claimant', rename: t('claim_display.claimant') },
    { val: 'app_submission_date', rename: t('claim_display.app_submission_date') },
    { val: 'pending_info', rename: t('claim_display.pending_info') },
    { val: 'status', rename: t('claim_display.status') },
    { val: 'payment', rename: t('claim_display.payment') },
    { val: 'payment_date', rename: t('claim_display.payment_date') },
  ];

  return displayItem;
}

export default ClaimItems;
