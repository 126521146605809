import React, { Component, useEffect, useState } from 'react';
import { Layout, Button, Row, Col, Divider } from 'antd';
import { useTranslation, initReactI18next } from "react-i18next";
// import * as ServiceCall from '../core/ServiceCall';
import { userLogin } from "../core/HandleService";
import LoginForm from '../component/form/LoginForm';
import LangButton from '../component/ChangeLangButton'
import { PhoneFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import AppLayout from '../component/AppLayout'
import HomeAppLayout from '../component/HomeAppLayout'

// logout api
import { userLogout } from '../core/HandleService'

import FunctionList from '../component/FunctionList';

function MemberPage() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem('userData'));
  const { userinfo } = userData;
  useEffect(() => {
  }, []);



  return (
    <AppLayout>
      {/* <h1>member home page</h1>
      <p>user name: {userinfo?.first_name} {userinfo?.last_name}</p>
      <p>e-point: {userinfo?.total_epoint}</p>
      <FunctionList />
      <Button onClick={() => { userLogout(); history.push('/home') }}>logout</Button> */}
    </AppLayout>
  )

}

export default MemberPage;
