import React, { Component, useEffect, useState } from 'react';
import { Layout, Button, Row, Col, Divider, Card, Breadcrumb } from 'antd';
import { useTranslation, initReactI18next } from "react-i18next";
// import * as ServiceCall from '../core/ServiceCall';
import { userLogin } from "../core/HandleService";
import LoginForm from '../component/form/LoginForm';
import LangButton from '../component/ChangeLangButton'
import { PhoneFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import AppLayout from '../component/AppLayout'
import HomeAppLayout from '../component/HomeAppLayout'
//display
import ProfileDisplay from '../component/ProfileDisplay'
// go back
import { GobackPage } from '../component/PageHeader'
import { policy_title_style } from './UserPolicyPage';
import ImageSlider from '../component/ImageSlider'
// import ImageSlider2 from '../component/slider/ImageSlider2'
import ImageSlider3 from '../component/ImageSlider3'
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import '../hideScrollbar.css'
// Define section images
const policyIcon = '/images/policy.png'
const profileIcon = '/images/profile.png'
const missionIcon = '/images/mission.png'
const formsIcon = '/images/forms.png'
const eclaimsIcon = '/images/eclaims.png'
const inboxIcon = '/images/inbox.png'
// Define hotpick images
const hotpickDomesticHelperExtraImg = '/images/domestic_helper_extra_square.png'
const homecareExtraSquareImg = '/images/homecare_extra_square.png'


function DashboardPage() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem('userData'));
  const { userinfo } = userData;

  const [ScreenSize,setScreenSize]=useState();
  useEffect(() => {
    resize();
    window.addEventListener("resize", resize)
  }, []);

  const resize=()=>{
    // console.log('!!!!!')
    setScreenSize({
      width:window.innerWidth,
      height:window.innerHeight,
    })
  }

  const goToNewTab = (link) => {
    window.open(link,'_blank');
  };

  const SectionData=[
    {id:'1',langKey:'dashboard.policy',img:policyIcon,
    action:()=>{
      history.push('/policy');
    }},
    {id:'2',langKey:'dashboard.profile',img:profileIcon,
    action:()=>{
      history.push('/profile');
    }},
    // {id:'3',langKey:'dashboard.mission',img:missionIcon,
    // action:()=>{
    //   history.push('/mission');
    // }},
    // {id:'4',langKey:'dashboard.forms',img:formsIcon,
    // action:()=>{

    // }},
    {id:'5',langKey:'dashboard.eclaims',img:eclaimsIcon,
    action:()=>{
      history.push('/claims');
      // window.open('https://forms.concordinsurance.com.hk/', '_blank');
    }},
    // {id:'6',langKey:'dashboard.inbox',img:inboxIcon,
    // action:()=>{

    // }},
  ];

  const FallbackLanguage=()=>{
    let keys = i18n.language.split('-')
    let languageKey = keys[0].toLowerCase();
    if( languageKey =='en'){
      return 'en'
    }else {
      return 'tw'
    }
  }

  const HotpickData=[
    {id:'1',
    title:{
      en:'DOMESTIC HELPER EXTRA',
      tw:'家庭僱傭保險(星級版)',
    },
    tag:{
      en:'Product Feature',
      tw:'產品特點',
    },
    desc:{
      en:['Medical Expenses for Abuse of insured & Family Members', 'Domestic Helper Surgical and Hospitalization Expenses', 'Fidelity Guarantee',],
      tw:[ '家庭成員受虐醫療費用', '家傭住院及手術費用高額保障', '家傭誠信保障', ],
    },
    img:hotpickDomesticHelperExtraImg,
    onClick:(FinalLanguage)=>{
      if (FinalLanguage === 'tw'){
        goToNewTab('https://b2c.concordinsurance.com.hk/zh/b2c/dhf?cref=31832cd1');
        return;
      }
      goToNewTab('https://b2c.concordinsurance.com.hk/b2c/dhf?cref=31832cd1');
    }},
    {id:'2',
    title:{
      en:'HOMECARE EXTRA',
      tw:'家居保險(星級版)',
    },
    tag:{
      en:'Product Feature',
      tw:'產品特點',
    },
    desc:{
      en:['HKD $1,000,000 All Risks cover of your Home Contents','HKD $10,000,000 Personal Legal Liability','Personal Cyber Protection'],
      tw:['家居物品百萬全險保障','個人法律責任千萬保障','網絡安全保障'],      
    },
    img:homecareExtraSquareImg,
    onClick:(FinalLanguage)=>{
      if (FinalLanguage === 'tw'){
        goToNewTab('https://b2c.concordinsurance.com.hk/zh/b2c/hcf?cref=a6d69d85');
        return;
      }
      goToNewTab('https://b2c.concordinsurance.com.hk/b2c/hcf?cref=a6d69d85');
    }},
  ];

  const Title = () => {
    // <div className="policy_profile_display">
    return (
      <div  className={'dashboardSectionContainer'} style={{fontSize:20 , fontWeight:'bold', display:'flex', justifyContent: isMobile? 'center':'flex-start'}}>

        <div style={{color: 'rgb(145 145 145)' }}>{t('dashboard.hotpicks')}</div>
        {/* <Breadcrumb separator=">" style={{ padding: '1%' }} >
          <Breadcrumb.Item >{t('header.profile')}</Breadcrumb.Item>
        </Breadcrumb> */}
      </div>
    )
  }

  var SectionRowWidth = document.getElementById('sectionRow')?.clientWidth;
  // console.log('SectionRowWidth:',getWindowDimensions())
  var isMobile = false;
  if(SectionRowWidth){
    if(getWindowDimensions().width <=576){ // 2 / row
      isMobile = true;
    }
  }
  const EachSection=(data,index)=>{
    // <Col key={index} md={8} sm={12} xs={24} style={{padding:10}}>
    // xs = screen < 576px  sm = screen ≥ 576px  md = screen ≥ 768px  lg = screen ≥ 992px  xl = screen ≥ 1200px  xxl = screen ≥ 1600px
    let SHeight=160;
    let SBorderRadius=30;
    let SfontSize = 26;
    if(SectionRowWidth){
      if(getWindowDimensions().width <480){
        SHeight = SectionRowWidth/2*0.5
        SBorderRadius=20;
        SfontSize = 16;
        let textDivWidth = SectionRowWidth/2*10/24
        SfontSize = textDivWidth * 16/78;
      }
      else if(getWindowDimensions().width <576){ // 2 / row
        SHeight = SectionRowWidth/2*0.5
        SBorderRadius=20;
        SfontSize = 16;
      }
      else if(getWindowDimensions().width <768){ // 2 / row
        SHeight = SectionRowWidth/2*0.5
        SBorderRadius=20;
        SfontSize = 20;
      }
      else if(getWindowDimensions().width <992){ // 2 / row
        SHeight = SectionRowWidth/2*0.5
        SBorderRadius=30;
        SfontSize = 26;
      }
      else if(getWindowDimensions().width >=992){ // 3 / row
        SHeight = SectionRowWidth/3*0.5
        SBorderRadius=30;
        SfontSize = 26;
      }
    }

    return(

      <Col key={index} lg={8} md={12} sm={12} xs={12}
      style={{padding:'2%'}}
      >

      <div onClick={()=>{data.action()}}
      style={{backgroundColor:'#fff',padding:20,height:SHeight,margin:'0px auto', padding:0,borderRadius:SBorderRadius, boxShadow: '0px 3px 2px 3px #CCCAB2',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}}
      >
      <div
      className={'dashboard_section_div'}
      style={{border:'solid 0px',width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <Col span={10} style={{ border:'solid 0px',}}>
          <div className={'dashboardSectionText'} style={{fontSize:SfontSize}}>{t(data.langKey)}</div>
        </Col>
        <Col span={10} style={{ border:'solid 0px',justifyContent:'flex-end',alignItems:'center',display:'flex'}}>
          <img src={data.img} style={{width:'70%',right:0}}/>
        </Col>
      </div>

      </div>

      </Col>

    )
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const SectionPart=()=>{
    // console.log(getWindowDimensions())
    let objs=[];
    SectionData.map((data,index)=>{
      objs.push(EachSection(data,index));
    })


    return(
      <div  className={'dashboardSectionContainer'}>
      <Row id={'sectionRow'}>
      { objs }
      </Row>
      </div>
    )
  }

  const EachHotpick=(data,index)=>{
    let HPWidth = 500;
    if(getWindowDimensions().width <=480){ //  1
      HPWidth = (getWindowDimensions().width *0.8);
    }
    else if(getWindowDimensions().width <=576){ //  1
      HPWidth = (getWindowDimensions().width *0.7);
    }

    let contentObjs=[];
    let FinalLanguage = FallbackLanguage()
    if(data?.desc[FinalLanguage]){
      data.desc[FinalLanguage].map((txt,index)=>{
        contentObjs.push(HotpickContentRow(txt,index))
      })
    }
    // console.log(data,i18n.language)
    return(
      <div
      key={index}
      //xxl={8} xl ={12} lg={12} md={12} sm={24} xs={24}
      style={{padding:'2%',justifyContent:'center',alignItems:'center',margin:'0px 0px',width:HPWidth,
      paddingBottom:30,
      border:'solid 0px'}}
      >
      <div onClick={()=>{data.onClick(FinalLanguage)}} style={{backgroundColor:'#fff', maxWidth:460,minHeight:340, height:'100%', margin:'0px auto', padding:20, borderRadius:6, boxShadow: '0px 2px 3px 2px #CCCAB2',display:'flex',cursor:'pointer'}}>
      <div style={{border:'solid 0px #f00',width:'100%',minHeight:340,display:'flex',justifyContent:'flex-start',alignItems:'center',flexDirection:'column'}}>


        <div style={{border:'solid 0px #0f0',width: '100%', height: '0px',paddingBottom:'100%',position:'relative'}}>
        <img src={data.img} style={{border:'solid 0px #00f',width:'100%', height:'100%',position:'absolute',borderRadius:30,boxShadow: '0px 2px 4px #CCCAB266'}}/>
        </div>

        <div style={{width:'100%',margin:'20px 0px 10px 0px',borderBottom:'solid 2px #E9E3D0'}}/>

        <div  style={{ border:'solid 0px',}}>
          <div className={''} style={{fontSize:26,fontWeight:'bold',color:'#666666',textAlign:'center'}}>{data.title[FinalLanguage]}</div>
        </div>

        <div  style={{ backgroundColor:'#E9E3D0',margin:'10px 0px',padding:'2px 20px', borderRadius:15}}>
          <div className={''} style={{fontSize:16,textAlign:'center'}}>{data.tag[FinalLanguage]}</div>
        </div>

        <div  style={{ border:'solid 0px',width:'100%', fontSize:16}}>
          {contentObjs}
        </div>

        {
        // <div style={{height:20}}/>
        }
        <div style={{display:'flex',flex:1}}/>

        <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
        {
          <Button
          onClick={()=>{data.onClick()}}
          style={{ backgroundColor:'#fff',border:'solid 1px #aaa',margin:'10px 0px',padding:'2px 20px', borderRadius:10,
          position:"absolute",
          transform:`translateY(20px)`,
          height:40,width:200}}>
            <div className={''} style={{fontSize:16,textAlign:'center',color:'#666'}}>{t('dashboard.quote_now')}</div>
          </Button>
        }
        </div>


      </div>
      </div>
      </div>
    )
  }

  const HotpickContentRow=(txt,index)=>{
    return(
      <div key={index} style={{display:'flex',justifyContent:'flex-start',alignItems:'flex-start',marginBottom:20}}>
      <div style={{margin:'0 4px 0 0'}}>❖</div>
      {txt}
      </div>
    )
  }


  const HotpickPart=()=>{
    // console.log(getWindowDimensions())
    let objs=[];
    let LRspace = getWindowDimensions().width*0.15;
    if(getWindowDimensions().width <=480){
      LRspace = getWindowDimensions().width * (1-0.8)/2
    }

    if(getWindowDimensions().width <=576){
      objs.push(
        <div key={'L'} style={{width:LRspace}}/>
      )
    }
    // HotpickData.map((data,index)=>{
    //   objs.push(EachHotpick(data,index));
    // })
    objs.push(EachHotpick(HotpickData[0],0));
    if(getWindowDimensions().width <=576){
      objs.push(
        <div key={'M'} style={{width:LRspace}}/>
      )
    }else {
      objs.push(
        <div key={'M'} style={{width:'10%'}}/>
      )
    }
    objs.push(EachHotpick(HotpickData[1],1));

    if(getWindowDimensions().width <=576){
      objs.push(
        <div key={'R'} style={{width:LRspace}}/>
      )
    }
    return(
      <div
      style={{ margin: '0 auto 20px auto', padding:isMobile? '0% 0% 0% 0%':'0% 15% 0% 15%'}}
      >

      {
        isMobile?
        <ScrollMenu
          // Header={<div>HEADER</div>}
          // Footer={<div>FOOTER</div>}
          LeftArrow={()=>RenderArrow('L')}
          RightArrow={()=>RenderArrow('R')}
          // onWheel={onWheel}
          style={{
            // border:'solid 1px #f00'
          }}
        >
        {objs}
        </ScrollMenu>
        :
        <div style={{
          display:'flex',
          justifyContent:'space-between',
        }}>
        {objs}
        </div>
      }

      </div>
    )
  }


  const RenderArrow=(direction)=>{
    const {
      isLastItemVisible,
      scrollNext,
      visibleItemsWithoutSeparators,
      isFirstItemVisible,
      scrollPrev,
      initComplete,
    } = React.useContext(VisibilityContext);
    let finalX = 0;
    let finalFunc = null
    let Arrow = null;
    let isDisable = false;
    if(direction == 'R'){
      finalX = -50
      finalFunc = scrollNext
      Arrow= <div style={{width:0,height:0,borderTop:'20px solid transparent',borderBottom:'20px solid transparent',borderLeft:'20px solid #a29463'}}/>
      if(visibleItemsWithoutSeparators.length){
        isDisable = isLastItemVisible
      }
    }else {
      finalX = 0
      finalFunc = scrollPrev
      Arrow= <div style={{width:0,height:0,borderTop:'20px solid transparent',borderBottom:'20px solid transparent',borderRight:'20px solid #a29463'}}/>
      if(visibleItemsWithoutSeparators.length){
        isDisable = isFirstItemVisible
      }
    }

    return(
      <div
      onClick={()=>{ finalFunc() }}
      disabled={isDisable}
      style={{
        width:50, height:50,
        position:'absolute',
        // border:'solid 1px',
        cursor:'pointer',
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',
        zIndex: 100,
        // transform:`translate(${finalX}px,${HotpickContainerDiv/2 -50}px)`,
        transform:`translate(${finalX}px,${760/2 -50}px)`,

      }}>
      {Arrow}
      </div>
    )
  }

  function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  }



  return (

    <AppLayout>
      {
        // <ImageSlider/>
      }
      {
        // <ImageSlider2/>
      }
      {
        <ImageSlider3/>
      }

      {
        <SectionPart/>
      }

      {
        <Title/>
      }
      {
        <HotpickPart/>
      }
    </AppLayout>
  )

}

export default DashboardPage;
