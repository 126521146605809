import { createStore } from 'redux';
import reducer from './Reducer';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, reducer)

export const store = createStore(persistedReducer);
// const store = createStore(reducer);

export let persistor = persistStore(store);

