import React, { Component } from 'react';
import {
  BrowserRouter, Redirect, Switch, Route
} from "react-router-dom";
import 'antd/dist/antd.css';

import Home from '../page/HomePage'
import ForgetPassword from '../page/ForgetPasswordPage'
import RegisterPage from '../page/RegisterPage'
import ContactUsPage from '../page/ContactUsPage';
import VerifyCodePage from '../page/VerifyCodePage';
import TestingApi from '../page/TestApi';
import ResetPasswordPage from '../page/ResetPasswordPage';
import MemberPage from '../page/MemberPage';
import UserPolicyPage from '../page/UserPolicyPage';
import ProfilePage from '../page/ProfilePage';
import UserPolicyDetailPage from '../page/UserPolicyDetailPage';
import ForgetUsername from '../page/ForgetUsernamePage'
import MissionPage from '../page/MissionPage';
import DashboardPage from '../page/DashboardPage';
import ClaimsPage from '../page/ClaimsPage';
import ClaimsDetailPage from '../page/ClaimsDetailPage'
import NotFound from '../component/NotFound';

import PrivateRoute from './PrivateRoute';

import UsingLocalStorageExample from '../component/UsingLocalStorageExample';
class AppRoute extends React.PureComponent {
  render() {
    // // console.log('Pure component AppRoute');
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/home/:lang?" component={Home}></Route>
          <Route path="/forget" component={ForgetPassword}></Route>
          <Route path="/register" component={RegisterPage}></Route>
          <Route path="/contactus" component={ContactUsPage}></Route>
          <Route path="/verify" component={VerifyCodePage}></Route>
          <Route path="/test-api" component={TestingApi}></Route>
          <Route path="/reset-password" component={ResetPasswordPage}></Route>
          <PrivateRoute path="/dashboard" component={DashboardPage} />
          <PrivateRoute path="/member-page" component={MemberPage} />
          <PrivateRoute path="/policy" component={UserPolicyPage} />
          <PrivateRoute path="/claims" component={ClaimsPage} />
          {/* <PrivateRoute path="/mission" component={MissionPage} /> */}
          <PrivateRoute path="/profile" component={ProfilePage} />
          <PrivateRoute path="/policy-detail" component={UserPolicyDetailPage} />
          <PrivateRoute path="/claim-detail" component={ClaimsDetailPage} />
          <Route path="/forget-name" component={ForgetUsername}></Route>

          {/* <Route path="/member-page" component={MemberPage}/> */}
          {/* <Route path="/using-localStorage" component={UsingLocalStorageExample}/> */}

          <Route restricted path="/404" component={NotFound} exact />
          <Redirect exact from="/*" to="/home" />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default AppRoute;
