import React, { Component, useEffect, useState } from 'react';
// import AppLayout from '../component/AppLayout'
import HomeAppLayout from '../component/HomeAppLayout'
import { useTranslation, initReactI18next } from "react-i18next";
import { PageHeader } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
//header page go back 
// import { GoBackPageHeader } from '../component/PageHeader';

//verify code form
import VerifyCodeForm from '../component/form/VerifyCodeForm';
function VerifyCodePage() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { register, data } = useLocation();
  if (!register) {
    history.goBack();
  }
  return (
    <HomeAppLayout>
      <VerifyCodeForm data={data} />
    </HomeAppLayout>
  )
}
export default VerifyCodePage;