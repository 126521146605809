import { getPolicyDetail, getPolicyDoc } from "../core/HandleService";
import { Form, Input, Button, Checkbox, Row, Col, message, Spin, Modal, Popover, Divider } from 'antd';
import React, { Component, useEffect, useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
import { SelectCallingCodeItem, TextInputItem_Optional, FunctionalInputItem, ButtonItem,SelectDateItem,SubmitButtonItem } from './form/FormComponents'
import { k11Recaptcha, k11Identity, bindMember,updateUserInfo } from '../core/HandleService'
//update local storage profile
import { update_user_profile_in_localstorage } from './CommonComponent';
import moment from 'moment'

const text_styles = {
  // fontSize: 20,
  // fontWeight: 'bold',
}

const Profile = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false)
  const userData = JSON.parse(localStorage.getItem('userData'));
  const { userinfo } = userData;

  const INITIAL_STATE = {
    bind_modal: { visible: false }
  }
  const [modalState, setModalState] = useState(INITIAL_STATE)
  const [dobEdit,setDobEdit] = useState('');
  const [form] = Form.useForm();

  if (!userinfo) return;
  // console.log('userinfo', userinfo);
  // console.log('userData', userData);

  const { username } = userData;
  // const { phone, hkid_display, total_epoint, policy_holder, member } = userinfo;
  const { phone, hkid_display, total_epoint, policy_holder, kdp_member_number, dob } = userinfo;
  // console.log(userinfo)
  const openModal = (action) => {
    switch (action) {
      case 'bind':
        setModalState({ ...modalState, bind_modal: { visible: true } })
        break
      default:
        return
    }
  }

  const closeModal = (action) => {
    switch (action) {
      case 'bind':
        setModalState({ ...modalState, bind_modal: { visible: false } })
        break
      default:
        return
    }
  }

  const checkValue=(str,max)=>{
    // console.log('checkValue:',str,max)
    if (str.charAt(0) !== '0' || str == '00') {
      var num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str = num > parseInt(max.toString().charAt(0))
      && num.toString().length == 1 ? '0' + num : num.toString();
    };
    return str;
  }

  const OnDOBInputChange=(obj)=>{
    // console.log("OnDOBInputChange:",obj.target.value,'|',dobEdit);
    // let regex = /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[./-]([0]?[1-9]|[1][0-2])[./-]([0-9]{4}|[0-9]{2})$/;
    let isRemove = false;
    let removeIndex = -1;
    var input = obj.target.value;
    if(dobEdit.length > obj.target.value.length){// remove last char
      isRemove = true
      if(dobEdit[dobEdit.length-1]=='-' &&  input[input.length-1] !='-'){
        input = input.substr(0,input.length-1);
        isRemove = false;
      }
    }
    var expr = new RegExp(/\D\/$/);
    if (expr.test(input)) input = input.substr(0, input.length - 3);
    var values = input.split('-').map(function(v) {
      return v.replace(/\D/g, '')
    });
    // console.log('values:',values);
    if (values[1]){ values[1] = checkValue(values[1], 12)};
    if (values[2]){ values[2] = checkValue(values[2], 31)};
    var output = values.map(function(v, i) {
      // console.log('vi:',v,i)
      if(i==0){
        if(v.length==4){ return v+'-'; }
        if(isRemove){
          if(v.length<4){ removeIndex = 0; }
        }
      }
      else if(i ==1){
        if(v.length==2){ return v+'-'; }
        if(isRemove){
          if(v.length<2){ removeIndex = 1; }
        }
      }
      else if(i==2){
        if(v.length==2){ return v; }
      }
      return v;
    });
    if(isRemove){
      if(removeIndex==0){
        output[1]='';
        output[2]='';
      }
      if(removeIndex==1){
        output[2]='';
      }
    }
    setDobEdit(output.join('').substr(0, 10))
    // console.log(lastTextAr,newDobAr,new_year)

  }
  const SubmitDOB=()=>{
    // console.log('SubmitDOB:',dobEdit)
    // let regex = /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[./-]([0]?[1-9]|[1][0-2])[./-]([0-9]{4}|[0-9]{2})$/;
    let regex =/^([0-9]{4})[-]([0-9]{2})[-]([0-9]{2})$/
    if(regex.test(dobEdit)){
      let dobEdit_m = moment(dobEdit)
      let year = dobEdit_m.year();
      let month = dobEdit_m.month() +1;
      let date = dobEdit_m.date();
      // console.log(year,month,date)
      if(isNaN(year) || isNaN(month)|| isNaN(date)){
        message.error(t('profile.invalid_dob_msg'));
        return;
      }else {
        // let today_m = moment();
        // let year18_m = moment().subtract(1,'year');
        // console.log(year18_m);
        // if( year >= today_m.year() ){
        //   message.error(t('profile.invalid_dob_msg'));
        // }
        call_api_updateUserInfo(dobEdit)
      }
    }else {
      message.error(t('profile.invalid_dob_msg')+' YYYY-MM-DD');
      return;
    }
  }

  async function call_api_updateUserInfo(dob_value){
    setLoading(true);
    if(userData==null) return;
    const{ userinfo} = userData;
    let submitData={}
    submitData.dob = dob_value;
    const result = await updateUserInfo(submitData);
    if(result?.success){
      // console.log('orgin',i18n.language, result)
      let userinfo_new = JSON.parse(JSON.stringify(userinfo))
      userinfo_new.dob = dob_value;
      update_user_profile_in_localstorage(userinfo_new);
      if(result?.message){
        message.success(result.message);
      }else {
        message.success(t('profile.dob_success'));
      }
    }else {
      message.error(result?.message)
    }
    setLoading(false);
  }

  const onFormFinish=(value)=>{
    // console.log('onFormFinish:',value.dob.format('YYYY-MM-DD'))
    call_api_updateUserInfo(value.dob.format('YYYY-MM-DD'))
  }

  // const x = '';
  // console.log(dob,userinfo,userData)
  return (
    <div >
      <Row className="policy_profile_display">
        <DisplayCol keyname={t('profile.holder')} data={policy_holder} />
        {/* <DisplayCol keyname={t('profile.username')} data={username} /> */}
        <DisplayCol keyname={t('profile.phone')} data={phone ?? '-'} />
        <DisplayCol keyname={t('profile.hkid')} data={hkid_display} />
        {
          dob?
          // x?
          <DisplayCol keyname={t('profile.dob_title')} data={dob} />
          // :null
          // <DisplayCol2 keyname={t('profile.dob')} data={hkid_display} />
          :
          <>
          <Col md={12} xs={24} style={{ textAlign: 'left' }}> {t('profile.dob_title')}</Col>
          <Col md={12} xs={24} style={{ textAlign: 'right', fontWeight: 'unset' }}>
          {
            /*
          <form>
          <div>

          <input
          value={dobEdit}
          placeholder={t('profile.dob_placeholder')}
          type={"date"}
          // min={'1900-01-01'}
          // max={'2030-01-01'}
          onChange={OnDOBInputChange}
          style={{
            // maxWidth:200,
            // minWidth:100,
            fontSize:16,
            textAlign:'right',
          }}/>

          <Button style={{ backgroundColor: '#ede6d0', color: '#6d6a60' ,marginLeft:20}} onClick={SubmitDOB}>{t('button.submit')}</Button>
          </div>
          </form>
          //*/
          }
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            // onValuesChange={onInputChange}
            onFinish={onFormFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
            style={{ width: '100%', padding: '0%' }}
          >
          {
            <SelectDateItem nameKey='dob' placeholder={t('profile.dob')} msg={t('profile.invalid_dob_msg2')} />
          }
          {
            <SubmitButtonItem name={t('forgetPasswordForm.submit')} style={{backgroundColor: '#ede6d0', color: '#6d6a60',border:'solid 1px #ddd',minWidth:64}} />
          }
          </Form>
          </Col>
          <Divider />
          </>
        }


        {/* <Col md={12} xs={24} style={{ textAlign: 'left' }}>{t('profile.member')}</Col>
        {!kdp_member_number ? (
          <Col md={12} xs={24} style={{ textAlign: 'right', fontWeight: 'unset' }}>
            <Button style={{ backgroundColor: '#ede6d0', color: '#6d6a60',borderRadius:8 }} onClick={() => openModal('bind')}>{t('profile.bind')}</Button>
          </Col>
        ) : (
          <Col md={12} xs={24} style={{ textAlign: 'right', fontWeight: 'unset' }}>{kdp_member_number}</Col>
        )}
        <Divider /> */}

        {/* <DisplayCol keyname={'Coney:'} data={total_epoint} /> */}
      </Row>
      {modalState.bind_modal.visible ? <BindModal visible={modalState.bind_modal.visible} onCancel={() => closeModal('bind')} /> : null}
    </div>
  );
}
const DisplayCol2 = ({ keyname, data }) => {
  return (
    <>
      <Col md={12} xs={24} style={{ textAlign: 'left' }}>{keyname}</Col>
      <Col md={12} xs={24} style={{ textAlign: 'right', fontWeight: 'unset' }}>
      <input />
      </Col>
      <Divider />
    </>
  )
}



const DisplayCol = ({ keyname, data }) => {
  return (
    <>
      <Col md={12} xs={24} style={{ textAlign: 'left' }}>{keyname}</Col>
      <Col md={12} xs={24} style={{ textAlign: 'right', fontWeight: 'unset' }}>{data}</Col>
      <Divider />
    </>
  )
}

const BindModal = ({ onCancel, ...rest }) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [showRecaptchaIuput, setShowRecaptchaIuput] = useState(true)
  const [callingCode, setCallingCode] = useState('')
  const [k11Mobile, setK11Mobile] = useState('')
  const [recaptcha, setRecaptcha] = useState('')
  const [memberNumber, setMemberNumber] = useState('')
  const [form] = Form.useForm();
  const [k11_user_info, setK11_user_info] = useState()

  async function get_recaptcha() {
    setLoading(true)
    const data = {
      kdp_member_phone_prefix: callingCode,
      kdp_member_phone: k11Mobile,
    }
    const result = await k11Recaptcha(data);
    if (result?.success) {
      message.success(t('registerForm.check_sms'))
      setShowRecaptchaIuput(false)
    } else {
      message.error(result?.message)
    }
    setLoading(false)
  }

  async function verify() {
    setLoading(true)
    const data = {
      kdp_member_phone_prefix: callingCode,
      kdp_member_phone: k11Mobile,
      captcha: recaptcha
    }
    const result = await k11Identity(data);
    if (result?.success) {
      setMemberNumber(result?.data.kdp_member_number)
      const { kdp_member_number, kdp_member_phone, kdp_member_phone_prefix } = result?.data;
      const params = {
        kdp_member_number: kdp_member_number,
        // kdp_member_phone:kdp_member_phone,
        // kdp_member_phone_prefix:kdp_member_phone_prefix
      }
      setK11_user_info(params);
      form.setFieldsValue({
        member: result?.data.kdp_member_number,
      })
    } else {
      message.error(result?.message)
    }
    setLoading(false)
  }

  async function bind() {
    setLoading(true)
    console.log(memberNumber)
    const data = {
      kdp_member_phone_prefix: callingCode,
      kdp_member_phone: k11Mobile,
      kdp_member_number: memberNumber,
    }
    const result = await bindMember(data);
    if (result?.success) {
      update_user_profile_in_localstorage(k11_user_info) // update profile
      message.success(t('profile.bind_success'))
    } else {
      message.error(result?.message)
    }
    setLoading(false)
  }

  const onInputChange = (changedValues) => {
    const fieldKey = Object.keys(changedValues)[0];

    if (fieldKey === 'calling_code') {
      setCallingCode(changedValues[fieldKey])
    }

    if (fieldKey === 'recaptcha') {
      setRecaptcha(changedValues[fieldKey])
    }

    if (fieldKey === 'k11_mobile' && !!changedValues[fieldKey]) {
      // setShowRecaptchaIuput(false)
      setK11Mobile(changedValues[fieldKey])
    } else if (fieldKey === 'k11_mobile' && !changedValues[fieldKey]) {
      setShowRecaptchaIuput(true)
    }
  }

  return (
    <Modal {...rest} footer={null} width={1000} destroyOnClose={true} onCancel={onCancel}>
      <Spin spinning={loading}>
        <Form
          form={form}
          onValuesChange={onInputChange}
          autoComplete="off"
          style={{ width: '100%', padding: '10%' }}
        >
          <Row>
            <Col span={5}>
              <SelectCallingCodeItem nameKey='calling_code' placeholder={t('registerForm.calling_code')} />
            </Col>
            <Col span={14} >
              <TextInputItem_Optional nameKey='k11_mobile' placeholder={t('registerForm.k11_mobile_required')} />
            </Col>
            <Col span={5}>
              <ButtonItem name={t('registerForm.getCaptcha')} onClick={get_recaptcha} />
            </Col>
          </Row>

          <Row>
            <Col span={19}>
              <FunctionalInputItem nameKey='recaptcha' placeholder={t('verify.verify_code')} hidden={showRecaptchaIuput}></FunctionalInputItem>
            </Col>
            <Col span={5}>
              <ButtonItem name={t('verify.title')} hidden={showRecaptchaIuput} onClick={verify} />
            </Col>
          </Row>

          <Row>
            <Col span={19}>
              <FunctionalInputItem nameKey='member' placeholder={t('profile.bind_member')} disabled="true" required_false={true}></FunctionalInputItem>
            </Col>
            <Col span={5}>
              <ButtonItem name={t('profile.bind')} onClick={bind} />
            </Col>
          </Row>

        </Form>
      </Spin>
    </Modal>
  )
}

export default Profile;
