
import { useTranslation, initReactI18next } from "react-i18next";

export const convertChannel = (detail_data , t)=>{
  // const { t, i18n } = useTranslation();
  var temp_data = '';
  switch (detail_data ){
    case 'Direct': temp_data = t('policy_api_channel.Direct'); break;
    case 'Agent': temp_data = t('policy_api_channel.Agent'); break;
    case 'Broker': temp_data = t('policy_api_channel.Broker'); break;
    case '直接投保': temp_data = t('policy_api_channel.Direct'); break;
    case '代理人': temp_data = t('policy_api_channel.Agent'); break;
    case '經紀': temp_data = t('policy_api_channel.Broker'); break;
    case 'Group': temp_data = t('policy_api_channel.Group'); break;
    case '團體': temp_data = t('policy_api_channel.Group'); break;
  }
  return temp_data;
}

export const convertPayment = (detail_data, t)=>{
  // const { t, i18n } = useTranslation();
  var temp_data = '';
  switch (detail_data ){
    case 'Credit Card': temp_data = t('policy_api_payment.payment_credit_card'); break;
    case 'Alipay': temp_data = t('policy_api_payment.payment_alipayhk'); break;
    case 'WeChat Pay': temp_data = t('policy_api_payment.payment_wechathk'); break;
    case 'Giveaway': temp_data = t('policy_api_payment.payment_privilege'); break;
    case '信用卡': temp_data = t('policy_api_payment.payment_credit_card'); break;
    case '支付寶': temp_data = t('policy_api_payment.payment_alipayhk'); break;
    case '微信支付': temp_data = t('policy_api_payment.payment_wechathk'); break;
    case '贈品': temp_data = t('policy_api_payment.payment_privilege'); break;
  }
  return temp_data;
}

export const convertStatus = (detail_data, t)=>{
  // const { t, i18n } = useTranslation();
  var temp_data = '';
  // switch (detail_data ){
  //   case 'Open': temp_data = t('status.open'); break;
  //   case 'Closed': temp_data = t('status.closed'); break;
  //   case 'In-Progress': temp_data = t('status.in_progress'); break;
  //   case '已申報': temp_data = t('status.open'); break;
  //   case '已完結': temp_data = t('status.closed'); break;
  //   case '處理中': temp_data = t('status.in_progress'); break;
  // }
  switch (detail_data ){
    case 'Open': 
    case '已申報': temp_data = t('status.open'); break;

    case 'Closed': 
    case '已完結': temp_data = t('status.closed'); break;

    case 'In-Progress':
    case '處理中': temp_data = t('status.in_progress'); break;
  }
  return temp_data;
}

export const convertPendingInfo = (detail_data, t)=>{
  // const { t, i18n } = useTranslation();
  var temp_data = '';
  // switch (detail_data ){
  //   case 'Supporting Documents': temp_data = t('pending_info.supporting_documents'); break;
  //   case 'Signed Form': temp_data = t('pending_info.signed_form'); break;
  //   case 'Others': temp_data = t('pending_info.others'); break;
  //   case '證明文件': temp_data = t('pending_info.supporting_documents'); break;
  //   case '簽妥之表格': temp_data = t('pending_info.signed_form'); break;
  //   case '其他': temp_data = t('pending_info.others'); break;
  // }
  switch (detail_data ){
    case 'Supporting Documents': 
    case '證明文件': temp_data = t('pending_info.supporting_documents'); break;

    case 'Signed Form':
    case '簽妥之表格': temp_data = t('pending_info.signed_form'); break;

    case 'Others': 
    case '其他': temp_data = t('pending_info.others'); break;
  }
  return temp_data;
}