import { Breadcrumb, Card } from 'antd';
import React, { Component, useEffect, useState } from 'react';
import _ from 'lodash';
import { Divider, Form, Input, Button, Checkbox, Row, Col, message, Spin, Modal, Popover, Table, Tag, Space } from 'antd';
import ConvertPDF, { DocButton } from './ConvertToPDF';
import { useTranslation, initReactI18next } from "react-i18next";

import policyItems from './PolicyItems';
import { convertChannel, convertPayment } from './ConvertDataLang';



const PolicyDetail = () => {
  const { t, i18n } = useTranslation();
  const userData = JSON.parse(localStorage.getItem('userData'));
  const policyDataDetail = JSON.parse(localStorage.getItem('policy_detail')); // policyDataDetail = {...}
  // console.log(displayItem)
  const displayItem = policyItems();
  return (
    <>
      <DisplayData policyDataDetail={policyDataDetail} displayItem={displayItem}  userData={userData}/>
    </>
  )
}

const DisplayData = ({ policyDataDetail, displayItem ,userData }) => {
  // console.log('1', policyDataDetail)
  // console.log('2', displayItem)
  const { t, i18n } = useTranslation();

  const handle_data_lang = (data, itemVal) => {
    var temp_data = data;
    if (itemVal == 'channel') { // channel change lang
      temp_data = convertChannel(data, t);
    } else if (itemVal == 'pay_method') { // payment change lang
      temp_data = convertPayment(data, t);
    }
    return temp_data;
  }

  return (
    <>
      <Card style={{ width: '100%', boxShadow: '3px 3px 9px #9BA3AA', borderRadius:10 }}>
        {/* <div style={{ width: divWidth(), margin: '0 auto', padding: '2%' }}> */}
        {
          _.map(displayItem, (item, index) => {

            return (
              <div key={index} style={{ margin: '3%' }}>
                <Row className="policy_profile_display">
                  {/* <DisplayCol keyname={item.rename+':'} data={policyDataDetail[item.val]} num={index} /> */}
                  <DisplayCol keyname={item.rename + ':'} data={handle_data_lang(policyDataDetail[item.val], item.val)} num={index} />
                </Row>
              </div>

            )
          })
        }

        {/* </div> */}
        <div style={{ margin: '3%' }}>
          <DocButton pol_no={policyDataDetail?.pol_no} />
        </div>

        {
          <div style={{ margin: '3%', marginTop: "-3%" }}>
          <Row className="policy_profile_display">
          {
            // <DisplayCol keyname={ ':'} data={'x'} num={'x'} />
          }
          {
            <>
              <Col md={12} xs={24} style={{ textAlign: 'left' }} key={'num' + 'a'}>{t('policy_display.eClaim') + ":"}</Col>
              <Col md={12} xs={24} style={{ textAlign: 'right',fontWeight:'unset' }} key={'num' + 'b'}>
              <button onClick={()=>{  
                const { userinfo } = userData;
                let hashCardID = userinfo.hkid;
                let policy_no = policyDataDetail.pol_no;
                let langParam = i18n.language.startsWith('tw') ? '&lang=ZH' : ''; 
                const eclaimUrl = process.env.REACT_APP_ECLAIM_URL;
                let baseUrl = `${eclaimUrl}ecl?c=${hashCardID}&pol=${policy_no}`;              
                
                if(policyDataDetail.prod_name == 'DOMESTIC HELPER'
                ||policyDataDetail.prod_name == 'DOMESTIC HELPER EXTRA'
                ||policyDataDetail.prod_name == 'HOMECARE EXTRA'
                ||policyDataDetail.prod_name == 'HOMECARE PLUS'
                ||policyDataDetail.prod_name == 'TRAVELSMILES'
                ||policyDataDetail.prod_name == 'TRAVELSMILES ANNUAL'){
             
                window.open(`${baseUrl}${langParam}`);

                } else {
                  let langParam = i18n.language.startsWith('tw') ? 'zh-hant' : 'en'; 
                  let baseUrl = `https://www.concordinsurance.com.hk/${langParam}/claims`
                  window.open(baseUrl)
                }
              }}
              style={{cursor:'pointer', backgroundColor: '#eee6cd', }}
              className="policy_download_btn">
              {t('policy_display.apply')}
              </button>
              </Col>
              <Divider />
            </>
          }
          </Row>
          </div>
        }
      </Card>
      {/* <Card style={{ width: '100%', boxShadow: '3px 3px 9px #9BA3AA', marginTop:'2%' }}>
        <div style={{ margin: '3%' }}>
          <DocButton pol_no={policyDataDetail?.pol_no} />
        </div>
      </Card> */}
    </>
  )


}
export const DisplayCol = ({ keyname, data, num }) => {
  return (
    <>
      <Col md={12} xs={24} style={{ textAlign: 'left' }} key={num + 'a'}>{keyname}</Col>
      <Col md={12} xs={24} style={{ textAlign: 'right',fontWeight:'unset' }} key={num + 'b'}>{data}</Col>
      <Divider />
    </>
  )
}


export default PolicyDetail;
