import { getPolicyDetail, getPolicyDoc } from "../core/HandleService";
import { Divider, Form, Input, Button, Checkbox, Row, Col, message, Spin, Modal, Popover, Table, Tag, Space } from 'antd';
import React, { Component, useEffect, useState } from 'react';
import _ from 'lodash';
// convert pdf
import ConvertPDF from './ConvertToPDF';
import { useTranslation, initReactI18next } from "react-i18next";
import { useHistory } from 'react-router-dom';
import policyItems from './PolicyItems';
// import styled from "styled-components";
import { convertChannel, convertPayment } from './ConvertDataLang';

const PolicyList = () => {
  const { t, i18n } = useTranslation();

  const userData = JSON.parse(localStorage.getItem('userData'));
  // console.log('local-user data:', userData)

  const [policyDataDetail, setPolicyDataDetail] = useState([]);
  // const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false);

  const displayItem = policyItems();


  useEffect(() => {
    call_api_policyDetail();  //get policy detail data
  }, [])

  async function call_api_policyDetail() {
    setLoading(true);
    if (userData == null) return;
    const { userinfo } = userData;
    const result = await getPolicyDetail({ hkid: userinfo.hkid });
    if (result?.success) {
      console.log('orgin', result.data.policies)
      // setPolicyDataDetail(result.data.policies);
      // handle_columns(result.data.policies);
      // handle_data(result.data.policies)
      processRecord(result.data.policies);
    } else {
      message.error(result?.message)
    }

    setLoading(false);
  }





  const processRecord = (recordList) => {
    var result = [];
    _.map(recordList, (record) => {
      var temp = {};
      _.map(displayItem, (item) => {
        const { val, rename } = item;
        if (val == 'tot_prem') {
          var num = parseFloat(record[val]).toLocaleString('en-US',{style:'currency',currency: 'USD'}) ;
          temp[val] = num.replace('$', '$ ');
        } else if(val == 'prod_name') {
          temp[val] = record[val].toUpperCase();
        } else {
          temp[val] = record[val];
        }
      })
      result.push(temp);
    })
    setPolicyDataDetail(result);
    return result;
  }

  // return (
  //   <>
  //     <DisplayData policyDataDetail={policyDataDetail} displayItem={displayItem} loading={loading} />
  //   </>
  // )


  return (
    <Spin spinning={loading}>
      {/* <Table columns={columns} dataSource={data} /> */}
      {/* <Table columns={tableColumns} dataSource={tableData} scroll={{ y: 300, x: '150vw' }} /> */}
      <DisplayData2 policyDataDetail={policyDataDetail} displayItem={displayItem} />
    </Spin>
  )
}

const DisplayData2 = ({ policyDataDetail, displayItem }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  // table
  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    console.log('lang changed');
    // handle_columns(policyDataDetail);
    // handle_data(policyDataDetail);
    process_columns_data();
  }, [localStorage.getItem("i18nextLng")])

  useEffect(() => {
    // handle_columns(policyDataDetail);
    // handle_data(policyDataDetail);
    process_columns_data();
  }, [policyDataDetail]);

  const process_columns_data = () => {
    handle_columns(policyDataDetail);
    handle_data(policyDataDetail);
  }

  const handle_columns = (policies) => {
    if (policies.length <= 0) return
    var columns = [];
    _.map(displayItem, (item) => {
      if (item.val == 'schedule') return;
      var obj = {
        title: item.rename,
        dataIndex: item.val,
        key: item.val,
      }
      switch (item.val) {
        // case 'prod_plan':
        // case 'tot_prem':
        // case 'channel':
        // case 'pol_eff_date':
        // case 'pay_method':
        // case 'pol_exp_date': obj.responsive = ['md']; break;
        case 'pol_no':
        case 'prod_name':
        case 'pol_eff_date': break;
        default: obj.responsive = ['md'];
      }
      columns.push(obj);
    })
    setTableColumns(columns)
  }

  const handle_data = (policies) => {
    if (policies.length <= 0) return
    var data = [];
    _.map(policies, (jsonItem, index) => {
      var temp_jsonItem = jsonItem;
      if (jsonItem['channel']) { // channel change lang
        temp_jsonItem['channel'] = convertChannel(jsonItem['channel'], t);
      }
      if (jsonItem['pay_method']) { // payment change lang
        temp_jsonItem['pay_method'] = convertPayment(jsonItem['pay_method'], t);
      }
      data.push({ ...temp_jsonItem, key: index });
    })
    setTableData(data)
  }

  const clickRow = (record) => {
    localStorage.setItem('policy_detail', JSON.stringify(record));
    history.push('/policy-detail');
  }
  // const StyledTable = styled((props) => <Table {...props} />)`
  //   && tbody > tr:hover > td {
  //     background: rgba(224, 248, 232, 1);
  //   }
  // `;
  return (
    <>
      <Table
        style={{background:'#ffffff',borderRadius:10}}
        className="policyTable"
        onRow={(record, rowIndex) => {
          return {
            onClick: event => { clickRow(record) }, // click row
            // onDoubleClick: event => { }, // double click row
            // onContextMenu: event => { }, // right button click row
            // onMouseEnter: event => { }, // mouse enter row
            // onMouseLeave: event => { }, // mouse leave row
          };
        }}
        // columns={tableColumns} dataSource={tableData} scroll={{ y: 600, x: '80vw' }} />
        columns={tableColumns} dataSource={tableData} scroll={{ x: '120vw' }} />
    </>
  )

}


export default PolicyList;
