import React from 'react';
import AppLayoutHeader from './AppLayoutHeader';
import AppLayoutFooter from './AppLayoutFooter';
import AppLayoutSider from './AppLayoutSider';
import { Layout, Menu, Divider, BackTop, Row, } from 'antd';
const { Content, Sider } = Layout;
const { SubMenu } = Menu;


class AppLayout extends React.Component {

  render() {
    const {
      children,
      noSidebar,
      title,
      contentCenter
    } = this.props;
    return (
      <Layout style={{
        minHeight: '100vh',
        // maxWidth: '1000px',
        margin: 'auto',
        background: '#FFFEFB',
      }}>

        {/* <AppLayoutSider/> */}

        {/* <Layout style={{ background: 'white' }}> */}
          <AppLayoutHeader />

          <Content>
            {
              contentCenter === true ? (
                <Row justify="center" align="middle" style={{ minHeight: '55vh' }}>
                  {children}
                </Row>
              ) : children
            }
          </Content>

          <AppLayoutFooter />
        {/* </Layout> */}

      </Layout>
    );
  }
}
export default AppLayout;
