import { useTranslation, initReactI18next } from "react-i18next";


const PolicyItems = () => {
  const { t, i18n } = useTranslation();

  const displayItem = [
    { val: 'pol_no', rename: t('policy_display.pol_no') },
    { val: 'prod_name', rename: t('policy_display.prod_name') },
    { val: 'prod_plan', rename: t('policy_display.prod_plan') },
    { val: 'tot_prem', rename: t('policy_display.tot_prem') },
    { val: 'channel', rename: t('policy_display.channel') },
    { val: 'issue_date', rename: t('policy_display.issue_date') },
    { val: 'pay_method', rename: t('policy_display.pay_method') },
    { val: 'pol_eff_date', rename: t('policy_display.pol_eff_date') },
    { val: 'pol_exp_date', rename: t('policy_display.pol_exp_date') },
    // { val: 'schedule', rename: t('policy_display.schedule') },
  ];

  return displayItem;
}

export default PolicyItems;