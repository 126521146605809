import { getClaimDetail } from "../core/HandleService";
import { Divider, Form, Input, Button, Checkbox, Row, Col, message, Spin, Modal, Popover, Table, Tag, Space } from 'antd';
import React, { Component, useEffect, useState } from 'react';
import _ from 'lodash';
// convert pdf
import ConvertPDF from './ConvertToPDF';
import { useTranslation, initReactI18next } from "react-i18next";
import { useHistory } from 'react-router-dom';
import ClaimItems from './ClaimItems'
// import styled from "styled-components";
import { convertChannel, convertPayment } from './ConvertDataLang';

const ClaimList = () => {
  const { t, i18n } = useTranslation();

  const userData = JSON.parse(localStorage.getItem('userData'));
  // console.log('local-user data:', userData)

  const [claimDataDetail, setclaimDataDetail] = useState([]);
  // const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false);

  const displayItem = ClaimItems();


  useEffect(() => {
    call_api_claimsDetail();  //get claims detail data
  }, [])

  async function call_api_claimsDetail() {
    setLoading(true);
    if (userData == null) return;
    const { userinfo } = userData;
    const result = await getClaimDetail({ hkid: userinfo.hkid });
    // const result = await getClaimDetail({ hkid: '18d8cdd1b15bce3b7a0ebd5492e62e29fcc7e61ca13616d706723d088ba4df49' }); // for test
    if (result?.success) {
      // console.log('orgin', result.data.claimsinfo)
      processRecord(result.data.claimsinfo);
    } else {
      message.error(result?.message)
    }

    setLoading(false);
  }





  const processRecord = (recordList) => {
    var result = [];
    _.map(recordList, (record) => {
      var temp = {};
      // console.log(record)
      _.map(displayItem, (item) => {
        // console.log(displayItem,item)
        const { val, rename } = item;
        if (val == 'payment') {
          var num = parseFloat(record[val]).toLocaleString('en-US',{style:'currency',currency: 'USD'}) ;
          temp[val] = num.replace('$', '$ ');
        }
        //else if(val == 'prod_name') {
        //   temp[val] = record[val].toUpperCase();
        // }
        else {
          temp[val] = record[val];
        }
      })
      temp['statusCN'] = record['statusCN'];
      temp['pending_infoCN'] = record['pending_infoCN'];
      result.push(temp);
    })
    // console.log('result:',result,recordList)
    setclaimDataDetail(result);
    return result;
  }

  // return (
  //   <>
  //     <DisplayData claimDataDetail={claimDataDetail} displayItem={displayItem} loading={loading} />
  //   </>
  // )


  return (
    <Spin spinning={loading}>
      {/* <Table columns={columns} dataSource={data} /> */}
      {/* <Table columns={tableColumns} dataSource={tableData} scroll={{ y: 300, x: '150vw' }} /> */}
      <DisplayData2 claimDataDetail={claimDataDetail} displayItem={displayItem} />
    </Spin>
  )
}

const DisplayData2 = ({ claimDataDetail, displayItem }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  // table
  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    // console.log('lang changed');
    // handle_columns(claimDataDetail);
    // handle_data(claimDataDetail);
    process_columns_data();
  }, [localStorage.getItem("i18nextLng")])

  useEffect(() => {
    // handle_columns(claimDataDetail);
    // handle_data(claimDataDetail);
    process_columns_data();
  }, [claimDataDetail]);

  const process_columns_data = () => {
    handle_columns(claimDataDetail);
    handle_data(claimDataDetail);
  }

  const handle_columns = (claims) => {
    if (claims.length <= 0) return
    var columns = [];
    _.map(displayItem, (item) => {
      if (item.val == 'schedule') return;
      var obj = {
        title: item.rename,
        dataIndex: item.val,
        key: item.val,
      }
      switch (item.val) {
        case 'refno':
        case 'polno':
        // case 'prod_name':
        case 'status': break;
        default: obj.responsive = ['md'];
      }
      columns.push(obj);
    })
    setTableColumns(columns)
  }

  const handle_data = (claims) => {
    if (claims.length <= 0) return
    var data = [];
    _.map(claims, (jsonItem, index) => {
      // console.log('handle_data:',jsonItem)
      var temp_jsonItem = JSON.parse(JSON.stringify(jsonItem));
      // if (jsonItem['channel']) { // channel change lang
      //   temp_jsonItem['channel'] = convertChannel(jsonItem['channel'], t);
      // }
      // if (jsonItem['pay_method']) { // payment change lang
      //   temp_jsonItem['pay_method'] = convertPayment(jsonItem['pay_method'], t);
      // }
      if(i18n.language=='en'){

      }else {
        temp_jsonItem.status = temp_jsonItem.statusCN;
        temp_jsonItem.pending_info = temp_jsonItem.pending_infoCN;
      }
      data.push({ ...temp_jsonItem, key: index });
    })
    // console.log('data:',data,claims)
    setTableData(data)
  }

  const clickRow = (record) => {
    localStorage.setItem('claim_detail', JSON.stringify(record));
    history.push('/claim-detail');
  }
  // const StyledTable = styled((props) => <Table {...props} />)`
  //   && tbody > tr:hover > td {
  //     background: rgba(224, 248, 232, 1);
  //   }
  // `;
  return (
    <>
      <Table
        style={{background:'#ffffff',borderRadius:10}}
        className="policyTable"
        onRow={(record, rowIndex) => {
          return {
            onClick: event => { clickRow(record) }, // click row
            // onDoubleClick: event => { }, // double click row
            // onContextMenu: event => { }, // right button click row
            // onMouseEnter: event => { }, // mouse enter row
            // onMouseLeave: event => { }, // mouse leave row
          };
        }}
        // columns={tableColumns} dataSource={tableData} scroll={{ y: 600, x: '80vw' }} />
        columns={tableColumns} dataSource={tableData} scroll={{ x: '120vw' }} />
    </>
  )

}


export default ClaimList;
