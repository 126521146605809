import React, { Component, useEffect, useState } from 'react';
import { Row, Col, Divider, Dropdown, Button } from 'antd'
import LangButton from './ChangeLangButton';
import { PhoneFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
//user dropdown
import UserDropdown from './UserDropdown';
// mobile components
import { Mobile_Header_indexBar,mobile_screen_size } from './MobileComponents';
//css

import "../styles.css";

import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';

const logo = '/images/logo.png';

//https://react-slideshow-image.netlify.app/
const ImageSlider = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [ScreenSize,setScreenSize]=useState();

  useEffect(() => {
    resize();
    window.addEventListener("resize", resize)
  }, []);

  const resize=()=>{
    console.log('!!!!!')
    setScreenSize({
      width:window.innerWidth,
      height:window.innerHeight,
    })
  }

  const slideImages = [
    {id:'1',name:'Slide 1',url:'https://concordinsurance.com.hk/images/home-banner2x.jpg',},
    {id:'2',name:'Slide 2',url:'https://concordinsurance.com.hk/images/home-banner2x.jpg',},
    {id:'3',name:'Slide 3',url:'https://concordinsurance.com.hk/images/home-banner2x.jpg',},
  ];

  const properties = {
    // indicators: (a,b)=>{
    //   console.log(a,b)
    //   return(
    //   <div style={{border:'solid 1px'}}>
    //   </div>
    // )},
    // duration: 5000,
    // transitionDuration: 500,
    // infinite: true,
    // prevArrow: <div style={{width: "30px", marginRight: "-30px"}}>
    //               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff">
    //               <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/>
    //               </svg>
    //            </div>,
    // nextArrow: <div style={{width: "30px", marginLeft: "-30px"}}>
    //               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff">
    //               <path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/>
    //               </svg>
    //            </div>
  };

  const EachImage=(item,index)=>{
    // console.log(ScreenSize?.width)
    let scaleHeight = 0;
    if(ScreenSize?.width){
      scaleHeight = ScreenSize.width * 1/2;
    }
    return(
      <div key={index}
      className="each-slide"
      style={{
        height:scaleHeight,
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        // backgroundSize: 'cover'
      }}>
      {
        // <div style={{'backgroundImage': `url(${item.url})`,backgroundSize: 'cover'}}>
        //   {
        //     // <span>{item.name}</span>
        //   }
        // </div>
      }

      {
        <img src={item.url} style={{width:'100%',height:scaleHeight ,objectFit: 'cover',}}/>
      }

      </div>
    )
  }

  const RenderSider=()=>{
    return(
      <Slide {...properties} easing="ease">
      {
        slideImages.map((item,index)=>EachImage(item,index))
      }
      </Slide>
    )
  }

  return (
    <div style={{ background: '#fff', padding: '0%', border: '0px solid #862633', fontSize: 20 , position:'relative' }}>
    {
      RenderSider()
    }  
    </div>
  );
}


export default ImageSlider;
