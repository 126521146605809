import { Row, Col, Divider, Dropdown } from 'antd'
import LangButton from './ChangeLangButton';
import { PhoneOutlined, WhatsAppOutlined, MailOutlined, FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
import { mobile_screen_size } from './MobileComponents';

const AppLayoutFooter = () => {
  var right_side_style = { textAlign: 'right' }
  if (window.innerWidth < 480) {
    right_side_style.textAlign = 'left';
    right_side_style.marginTop = '20px';
  }
  return (
    <div style={{ background: '#4b4b4b', padding: '2%', color: '#b4b4b4', height: '10%' }}>


      <Row>
        <Col md={11} xs={24} style={{ textAlign: 'left', border: 'unset' }}>
          <LeftSide />
        </Col>
        <Col md={2} xs={24} style={{ textAlign: 'center' }}>
          {window.innerWidth >= 480
            ?
            <Divider type="vertical" style={{ 'backgroundColor': '#b4b4b4', height: '100%' }} />
            :
            <>
              <br />
              <br />
            </>
          }
          {/* <Divider type="vertical" style={{ 'backgroundColor': '#b4b4b4', height: '100%' }} /> */}
        </Col>
        <Col md={11} xs={24} style={right_side_style}>
          <RightSide />
        </Col>
      </Row>
    </div>
  );
}
const styles = {
  color: '#b4b4b4',
  // textDecoration: 'underline'
}
const LeftSide = () => {
  const { t, i18n } = useTranslation();

  return (
    <div >
      <p style={{ fontSize: '20px', fontweight: 'bold' }}>{t('contactus.title')}</p>
      {
        mobile_screen_size() ?
          ''
          :
          <p>{t('companyInfo.address_office')}</p>
      }
      <p><PhoneOutlined />: <a href={"tel:" + t('companyInfo.tel')} style={styles}>{t('companyInfo.tel')}</a></p>
      {/* <p><WhatsAppOutlined />: <a href="https://wa.me/85293199628" target="_blank" rel="noopener noreferrer" style={styles}>{t('companyInfo.wts')}</a></p> */}
      <p><MailOutlined />: <a href={"mailto: " + t('companyInfo.email')} style={styles}>{t('companyInfo.email')}</a></p>
      {
        mobile_screen_size() ?
          ''
          :
          <>
            <p><FacebookOutlined /> <a href="http://www.facebook.com/concordinsurancehk" target="_blank" rel="noopener noreferrer" style={styles}>: concordinsurancehk</a> </p>
            <p><InstagramOutlined /> <a href="https://www.instagram.com/concordinsurancehk/" target="_blank" rel="noopener noreferrer" style={styles}>: concordinsurancehk</a> </p>
          </>
      }
    </div>
  )
}
const RightSide = () => {
  const { t, i18n } = useTranslation();

  const FallbackLanguage=()=>{
    let keys = i18n.language.split('-')
    let languageKey = keys[0].toLowerCase();
    if( languageKey =='en'){
      return 'en'
    }else {
      return 'tw'
    }
  }

  const lang = FallbackLanguage();

  const linkPrivacyPolicy = {
    'en': 'https://www.concordinsurance.com.hk/en/privacy-policy',
    'tw': 'https://www.concordinsurance.com.hk/zh-hant/privacy-policy',
  };

  const linkTerms = {
    'en': 'https://www.concordinsurance.com.hk/en/terms-of-use',
    'tw': 'https://www.concordinsurance.com.hk/zh-hant/terms-of-use',
  };

  var left_right = { right: '0px' };
  if (window.innerWidth < 480) {
    left_right = { left: '0px' };
  }

  return (
    <div style={{ ...left_right, position: 'absolute', bottom: '0px', margin: '0px' }}>
      <a href={linkPrivacyPolicy[lang]} target="_blank" rel="noopener noreferrer" style={styles}>{t('footer.privacy')}</a>
      <Divider type="vertical" style={{ 'backgroundColor': '#b4b4b4' }} />
      <a href={linkTerms[lang]} target="_blank" rel="noopener noreferrer" style={styles}>{t('footer.terms')}</a>
      <p style={{ fontSize: '10px' }}>{t('footer.all_right')}<br />{t('footer.all_right2')}</p>
      {/* <p>{t('footer.all_right2')}</p> */}
    </div>
  )
}

export default AppLayoutFooter;