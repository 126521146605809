import React from 'react';
import { Route, Redirect } from 'react-router-dom';
//redux
// import { connect, useSelector, useDispatch, useStore } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
  // check token / user info in store

  //get data form localStorage
  // const isLogin = localStorage.getItem('accessToken') || false

  //get data from redux
  // const userInfo = useSelector(state => state.userInfo);
  // const isLogin = Object.keys(userInfo) != 0;

  const isLogin = localStorage.getItem('accessToken')|| false ;
  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      isLogin ?
        <Component {...props} />
        : <Redirect to="/home" />
    )} />
  );
};

export default PrivateRoute;