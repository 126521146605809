import React, { Component, useEffect, useState } from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { PageHeader } from 'antd';


export function GoBackPageHeader() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  return (
    <PageHeader
      className="site-page-header"
      onBack={() => { history.push('/home') }}
      title={t('header.back')}
    // subTitle="This is a subtitle"
    />
  )
}
export function GobackPage() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  return (
    <PageHeader
      className="site-page-header"
      // onBack={() => { window.history.go(-1) }}
      onBack={() => { history.push('/member-page') }}
      title={t('header.back')}
    // subTitle="This is a subtitle"
    />
  )
}