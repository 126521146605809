import React, { Component, useEffect, useState } from 'react';
import AppLayout from '../AppLayout'
import { useTranslation, initReactI18next } from "react-i18next";
import { PageHeader } from 'antd';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Row, Col, message } from 'antd';
// form component
import { EmailInputItem, SubmitButtonItem, PasswordInputItem, ConfirmPasswordInputItem } from './FormComponents'
//header page go back 
// import { GoBackPageHeader } from './PageHeader';

//call api
import { resetPassword } from '../../core/HandleService.js';

function ResetPasswordForm({ token, email }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  async function call_api(data) {
    const params = { ...data, ...{ token: token }, ...{ email: email } }
    const result = await resetPassword(params);
    if (result?.success) {
      message.success(t('resetPassword.success'))
      setTimeout(() => {
        history.push('/home')
      }, 2000);
    } else {
      message.error(result?.message)
    }
  }

  const onFinish = (values) => {
    // console.log('Success:', values);
    // message.success('send success')
    call_api(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{ width: '100%', padding: '10%' }}
      >
        <h1>{t('resetPassword.title')}</h1>

        <PasswordInputItem nameKey='password' placeholder={t('registerForm.password')} msg={t('registerForm.password_msg')} />

        <ConfirmPasswordInputItem nameKey='password_confirmation' placeholder={t('registerForm.confirm_password')}
          msg={t('registerForm.confirmPassword_msg_notMatch')} dependencies='password' notMatch={t('registerForm.confirmPassword_msg_notMatch')} />

        <SubmitButtonItem name={t('button.submit')} />

      </Form>
    </>
  )
}
export default ResetPasswordForm;