import React, { Component, useEffect, useState , useRef } from 'react';
import { Layout, Button, Row, Col, Divider,message, Card, Breadcrumb,Spin,Modal } from 'antd';
import { useTranslation, initReactI18next } from "react-i18next";
// import * as ServiceCall from '../core/ServiceCall';
import { userLogin , getMissionList,redeemMission,getReferralStatus,getReferralCode } from "../core/HandleService";
import LoginForm from '../component/form/LoginForm';
import LangButton from '../component/ChangeLangButton'
import { PhoneFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import AppLayout from '../component/AppLayout'
import HomeAppLayout from '../component/HomeAppLayout'
//display
import ProfileDisplay from '../component/ProfileDisplay'
// go back
import { GobackPage } from '../component/PageHeader'
import { policy_title_style } from './UserPolicyPage';
import ImageSlider from '../component/ImageSlider'
import ImageSlider3 from '../component/ImageSlider3'
import ReferralCodeModal from '../component/ReferralCodeModal';

function MissionPage() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem('userData'));
  const { userinfo } = userData;

  const [loading, setLoading] = useState(false);

  const [inviteData,setInviteData] = useState([]);
  const [specialDiscountData,setSpecialDiscountData] = useState([]);
  const [upperCheckData,setUpperCheckData] = useState();
  const [referralData,setReferralData] = useState();

  const [referInputs,setReferInputs] = useState({});
  const referReferralCodeModal = useRef(null);

  const [ScreenSize,setScreenSize]=useState();

  const [showID, setShowID] = useState({});

  const resize=()=>{
    // console.log('!!!!!')
    setScreenSize({
      width:window.innerWidth,
      height:window.innerHeight,
    })
  }
  useEffect(() => {
    window.addEventListener("resize", resize)
    // let tempInviteData={
    //     dataList:[
    //       // {id:'0',name:'被推薦人',score:50,desc:'',status:'redeem',friend_code:'X12345678'},
    //       // {id:'1',name:'好友邀請計劃（初級）',score:20,desc:'成功推薦每位新朋友投保',status:'refer'},
    //       // {id:'2',name:'好友邀請計劃（二級）',score:50,desc:'',status:'pending'},
    //       // {id:'3',name:'好友邀請計劃（三級）',score:20,desc:'',status:'refer'},
    //       // {id:'4',name:'好友邀請計劃（四級）',score:50,desc:'',status:'pending'},
    //     ],
    // }
    // setInviteData(tempInviteData)
    //
    // let tempSpecialDiscountData={
    //     dataList:[
    //       // {id:'1',name:'生日禮物',score:20,desc:'XXX祝你生日快樂',status:'refer'},
    //       // {id:'2',name:'多多益善',score:50,desc:'多重投保，同期購入每多一項產品',status:'pending'},
    //     ],
    // }
    // setSpecialDiscountData(tempSpecialDiscountData)

    let tempUpperCheckData={
        dataList:[
          {id:'0',status:2},
          {id:'1',status:1},
          {id:'2',status:0},
          {id:'3',status:0},
          {id:'4',status:0},
        ],
    }
    setUpperCheckData(tempUpperCheckData)

    call_api_missionList();
    // call_api_getReferralStatus();
  }, []);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const ContentWidth = getWindowDimensions().width*0.8;

  const ScreenWidth = getWindowDimensions().width;

  async function call_api_getReferralStatus(){
    setLoading(true);
    if (userData == null) return;
    const { userinfo } = userData;
    const result = await getReferralStatus({ });
    if (result?.success) {
      // console.log('call_api_getReferralStatus:',i18n.language, result)
      setReferralData(result.data); // {referral_count,total}
    } else {
      message.error(result?.message)
    }
    setLoading(false);
  }

  async function call_api_missionList(){
    setLoading(true);
    if (userData == null) return;
    const { userinfo } = userData;
    // console.log(userData.userinfo.referrer_code);
    const result = await getMissionList({ });
    if (result?.success) {
      // console.log('call_api_missionList:',i18n.language, result)
      // setPolicyDataDetail(result.data.policies);
      // handle_columns(result.data.policies);
      // handle_data(result.data.policies)
      // processRecord(result.data.policies);
      if(result.data['好友邀請']){
        setInviteData({dataList:result.data['好友邀請']});
      }
      if(result.data['Referrer']){
        setInviteData({dataList:result.data['Referrer']});
      }
      if(result.data['特別優惠']){
        setSpecialDiscountData({dataList:result.data['特別優惠']});
      }
      if(result.data['Special']){
        setSpecialDiscountData({dataList:result.data['Special']});
      }
      call_api_getReferralStatus();
    } else {
      message.error(result?.message)
    }
    setLoading(false);
  }

  async function call_api_redeemMission(rowData,value,callback){
    // console.log(rowData, value)
    // return;
    setLoading(true);
    if (userData == null) return;
    const { userinfo } = userData;

    let submitData = {}
    submitData.mission_id = rowData.id;
    let dataObj = null;//{code:""};
    if(rowData.is_referred == 1){
      dataObj = {code:value};
      submitData.data = JSON.stringify(dataObj);
    }
    const result = await redeemMission(submitData);
    if (result?.success) {
      // console.log('orgin',i18n.language, result)
      if(callback){ callback(true);}
      if(result?.message){
        // message.success(result.message);
        ShowTimoutModalMsg({msg:result?.message})
      }else {
        // message.success(t('mission_page.redeem_success'));
        if(rowData.is_referred==1){
          if(userinfo.kdp_member_number){
            ShowTimoutModalMsg({msg:t('mission_page.redeem_success')})
          }else {
            ShowTimoutModalMsg({msg:t('mission_page.redeem_success_withoutK11')})
          }
        }else {
          ShowTimoutModalMsg({msg:t('mission_page.redeem_success')})
        }
      }
      call_api_missionList()
    } else {
      if(callback){ callback(false);}
      // console.log(result)
      // message.error(result?.message)
      ShowTimoutModalMsg({msg:result?.message,type:'error'})
    }
    setLoading(false);
  }

  const ShowTimoutModalMsg=({msg,type,duration=150000})=>{
    let modal = null
    let content = {
      centered:true,
      content:msg,
      okText:t('confirm')
    }
    switch (type) {
      case 'info':
        modal = Modal.info(content)
        break;
      case 'error':
        modal = Modal.error(content)
        break;
      case 'success':
        modal = Modal.success(content)
        break;
      default:
        modal = Modal.info(content)
      break;
    }
    if(modal){
      setTimeout(()=>{
        modal.destroy();
      },duration)
    }
  }


  async function call_api_getReferralCode(){
    setLoading(true);
    if (userData == null) return;
    const { userinfo } = userData;

    const result = await getReferralCode({ });
    if (result?.success) {
      // console.log('call_api_getReferralCode:',i18n.language, result)
      var code = result.data.referral_code;
      referReferralCodeModal.current.OpenModal(code);
    } else {
      message.error(result?.message)
    }
    setLoading(false);
  }

  const onClickMyReferralCode=()=>{
    // referReferralCodeModal.current.OpenModal('123');
    // return;
    call_api_getReferralCode()
  }

  const onClickOffersTerms=()=>{
    console.log('onClickOffersTerms')
  }

  const onClickRedeem=(data)=>{
    if(data.is_referred == 1 ){//||data.is_referred == true){
      referReferralCodeModal.current.OpenInput(data);
    }else {
      call_api_redeemMission(data);
    }
  }

  const onInputCallback=(value,data)=>{
    // console.log(value,data)
    call_api_redeemMission(data,value,(isSuccess)=>{
      if(isSuccess){
        // referReferralCodeModal.current.CloseModal()
      }
    });
  }

  const Display_breadcrumb = () => {
    return (
      <div className="policy_profile_display">
        <div style={{ padding: '1%', color: 'rgb(145 145 145)' }}>{t('header.profile')}</div>
        {/* <Breadcrumb separator=">" style={{ padding: '1%' }} >
          <Breadcrumb.Item >{t('header.profile')}</Breadcrumb.Item>
        </Breadcrumb> */}
      </div>
    )
  }


  const MissionProgress_subtitle=(txt)=>{
    return(
      <div style={{display:'flex',flexDirection:'column',border:'solid 0px',minWidth:90,margin:'0px 0px 0px 0px' ,justifyContent:'center',alignItems:'center'}}>
      <div style={{color:"#353535",fontSize:17,fontWeight:'bold',margin:'0px 15px'}}>
      {txt}
      </div>
      <div style={{ width:'100%',height:7,borderRadius:7/2,backgroundColor:'#E0E0E0',boxShadow: '0px 1.5px 3px 1px #0000002a' }}/>
      </div>
    )
  }

  const MissionProgress_obj=(data,index)=>{
    // console.log(data.status)
    // data.status = 'ready'

    let MPO_style={
      color:'#707070',
      backgroundColor:'#ffffff',
    }
    let MPO_outterStyle={ }

    if(data.status == 'redeemed'){
      MPO_style.backgroundColor = '#807D7D'
      MPO_style.color = '#CDCDCD'
    }
    else if(data.status == 'pending'){
      MPO_style.backgroundColor = '#ffffff'
    }
    else if(data.status == 'ready'){
      MPO_style.backgroundColor = '#FCFFA3'
      MPO_style.boxShadow= '5px 5px 5px 1px #0000002a inset'
      MPO_outterStyle.boxShadow ='5px 5px 5px 1px #0000002a'
    }
    return(
      <div key={'mpo'+index}
      style={{
        width:138,height:86,borderRadius:27,
        margin:'0px 8px 10px 8px',
        ...MPO_outterStyle
      }}>
      <div
      style={{
        width:138,height:86,borderRadius:27,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        fontSize:22,
        ...MPO_style,
      }}>
      {i18n.language=='en'? data.name_en:data.name_zh}
      </div>
      </div>
    )
  }

  const MissionProgress =()=>{
    let inviteDataObjs = [];
    if(inviteData?.dataList){
      inviteData.dataList.map((data,index)=>{
        // if(index>=1){ return; }
        inviteDataObjs.push(MissionProgress_obj(data,index))
      })
    }

    let specialDataObjs = [];
    if(specialDiscountData?.dataList){
      specialDiscountData.dataList.map((data,index)=>{
        specialDataObjs.push(MissionProgress_obj(data,index))
      })
    }

    // console.log('ContentWidth:',ContentWidth,getWindowDimensions().width)
    let maxItmeWidth = ContentWidth - 30*2 -50;
    let invitationExtraStyle={
      // justifyContent:'center',
    }
    let specialDiscountExtraStyle = {
      // justifyContent:'center',
    }
    if(inviteData?.dataList){
      if(inviteData.dataList.length * (138+8*2) > maxItmeWidth ){
        invitationExtraStyle.flexWrap = 'wrap';
        // invitationExtraStyle.justifyContent = 'space-evenly';
        // invitationExtraStyle.justifyContent = 'center';
      }
    }
    if(specialDiscountData?.dataList){
      if(specialDiscountData.dataList.length * (138+8*2) > maxItmeWidth ){
        specialDiscountExtraStyle.flexWrap = 'wrap';
        // specialDiscountExtraStyle.justifyContent = 'space-evenly';
        // specialDiscountExtraStyle.justifyContent ='center';
      }
    }

    let MissionProgressMinWidth = 450;
    let titleRowContainerStyle={
      padding:'0px 30px'
    };

    if(ContentWidth <480){
      MissionProgressMinWidth = 340
      titleRowContainerStyle.padding = '0px 10px'
      invitationExtraStyle.paddingLeft = 50;
      specialDiscountExtraStyle.paddingLeft = 50;
    }
    else if(ContentWidth <576){
      titleRowContainerStyle.padding = '0px 20px'
      invitationExtraStyle.paddingLeft = 50;
      specialDiscountExtraStyle.paddingLeft = 50;
    }else{
      invitationExtraStyle.paddingLeft = 50;
      specialDiscountExtraStyle.paddingLeft = 50;
    }


    return(
      <div style={{ margin: '0 auto', padding: '0% 10% 0% 10%' ,border:'solid 0px',display:'flex',justifyContent:'center'}}>
      <div style={{
        minWidth:MissionProgressMinWidth,minHeight:380,
        borderRadius:50,
        margin:'50px 0px 10px 0px',
        backgroundColor:'#F9F9F4',
        boxShadow: '1.5px 1.5px 3px 1px #000000a0'
      }}>
      <div style={{
        display:'flex',
        height:59,
        justifyContent:'center',
        alignItems:'center',
        ...titleRowContainerStyle,
      }}>
        <div className={'mission-progress-dot'}/>
        <div className={'mission-progress-title'}
        // style={{color:"#918E7F",fontSize:42,fontWeight:'bold',margin:'0px 28px',textShadow: '0px 1.5px 3px #0000002a'}}
        >
        {t('mission_page.mission_progress')}
        </div>
        <div className={'mission-progress-dot'}/>
      </div>
      <div style={{ width:'100%',height:7,backgroundColor:'#b1aaaa' }}/>

      <div style={{display:'flex',flexDirection:'column',border:'solid 0px',justifyContent:'flex-start',alignItems:'flex-start',padding:'0px 30px'}}>
        {
          MissionProgress_subtitle(t('mission_page.recommend_friend'))
        }
        <div className={'miss-progress-item-container'}
        // style={{display:'flex' ,minHeight:86,margin:'10px 30px 10px 30px', border:'solid 0px', flexWrap:'wrap',justifyContent:'space-evenly' }}
        // style={{display:'flex' ,minHeight:86,margin:'10px 30px 10px 30px', border:'solid 0px', }}
        // style={{display:'grid' ,minHeight:86,margin:'10px 30px 10px 30px', border:'solid 1px',
        // // flexWrap:'wrap',
        //  gridTemplateColumns: `repeat(3, 1fr)`,
        // }}
        style={{...invitationExtraStyle}}
        >
        {
          inviteDataObjs
        }
        </div>
        {
          MissionProgress_subtitle(t('mission_page.special_promotion'))
        }

        <div className={'miss-progress-item-container'}
        // style={{display:'flex' ,minHeight:86,margin:'10px 30px 10px 30px', border:'solid 0px', }}
        style={{...specialDiscountExtraStyle}}
        >
        { specialDataObjs }
        </div>


      </div>

      </div>
      </div>
    )
  }

  const TitleLine=({title,rightComponent})=>{
    return(
      <div className={'missionTitleLineContainerDiv'} >

      <div style={{display:'flex',flexDirection:'row',border:'solid 0px',justifyContent:'space-between',alignItems:'center'}}>
      <div className={'missionTitleLineTxt'} style={{marginRight:10}}>{title}</div>
      {rightComponent}
      </div>
      <Divider style={{margin:'10px 0 0px 0'}}/>
      </div>
    )
  }

  const DataRow=(data,index)=>{
    // console.log(i18n.language,data)
    // const [isShowDetail,setIsShowDetail ] = useState(false);
    const isShowDetail = showID && showID[data.id]? true:false
    let btnTxt = '  ';
    if(data.status){
      btnTxt = t('status.'+data.status)
    }
    let ImgSize = ContentWidth*(7/24) - 15;
    // console.log(ImgSize,ContentWidth)
    if(ImgSize > 200 ){
      ImgSize = 200
    }
    let ImgContainerSpan = 7;
    let TitleContainerSpan = 10;
    let PointsContainerSpan = 7;

    let ImgContainerStyle = {
      display:'flex',justifyContent:'center',padding:'0px 0px 0px 15px',
      border:'solid 0px',
      flex:7,
    }

    let TitleContainerStyle={
      display:'flex', flexDirection: 'column',
      padding:'0px 4px' ,border:'solid 0px',padding:'0px 0px 0px 15px',
      flex:10,
    }

    let PointsContainerStyle={
      height:'100%',
      display:'flex', flexDirection: 'column', justifyContent:'flex-end', alignItems:'flex-end',
      padding:'0px 20px 10px 0px',
      flex:7,
    }

    let ImageTitleContainerStyle={

    }

    if(ScreenWidth <360){
      ImgSize = ContentWidth*(7/24) - 15

      PointsContainerStyle.padding = '0px 4px 8px 0px'
      ImgContainerStyle.padding='0px 0px 0px 0px'

      TitleContainerStyle.padding='0px 0px 0px 5px'
    }
    else if(ScreenWidth < 480){
      ImgContainerStyle.padding = '0px 0px 0px 5px'

      PointsContainerStyle.padding = '0px 8px 10px 0px'

      ImageTitleContainerStyle.flex = 2

      ImgSize = ContentWidth*(7/24) - 15
    }
    else if (ScreenWidth < 576) {
      PointsContainerStyle.padding = '0px 10px 10px 0px'
      ImgContainerStyle.padding='0px 0px 0px 8px'
    }
    else if (ScreenWidth < 768) {
      ImgContainerStyle.padding='0px 0px 0px 10px'
    }


    return(
      <div key={index} className={'missionObjContainerDiv'} style={{ }}>
      <Row>
      <Col md={24} xs={24} style={{
        // backgroundColor:'#C7D0D8',
        minHeight:'160px',
        border:'solid 0px',
      }}>
      <Col style={{height:'100%',border:'solid 0px',padding:'5px 0px' , display:'flex', flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
      <div style={{width:'100%',height:'100%', display:'flex',justifyContent:'center',alignItems:'center',...ImageTitleContainerStyle}}>
      {
        <Col
        // span={ImgContainerSpan}
        // md={7} xs={7}
        style={{...ImgContainerStyle}}>
        {
          <div style={{
            width:ImgSize,
            height:ImgSize,
            borderRadius:ImgSize,
            // backgroundColor:'#f1f1f1',
            // border:'solid 1px #707070',
            display:'flex'
          }}>
          <img src={data.image_url} style={{
            width:ImgSize,
            height:ImgSize,
            borderRadius:ImgSize,}}/>
          </div>
        }
        </Col>
      }

      {
        <Col
        // span={TitleContainerSpan}
        // md={10} xs={10}
        style={{...TitleContainerStyle}}>

          {
            <div className={"missionObjTitle"}
            style={{border:'solid 0px',}}
            dangerouslySetInnerHTML={{__html:
              i18n.language=='tw'?
              data.short_desc_zh:data.short_desc_en
            }} />
          }

          {
            <div className={"descText"} style={{marginTop:'5px',display:'flex'}}>
            <div style={{ border:isShowDetail?'solid 1px':'solid 0px',

              backgroundColor:isShowDetail?'#6f6f6f33':null,
              padding:'0px 10px',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}}
            onClick={()=>{
              // console.log('!!!!!',data,index)
              // setIsShowDetail(!isShowDetail);
              // data.isShowDetail = true
              let tempObj = JSON.parse(JSON.stringify(showID))
              if(showID[data.id]){
                delete tempObj[data.id];
                setShowID(tempObj)
              }else {
                tempObj[data.id] = true;
                setShowID(tempObj)
              }
            }}>
            <div className={"missionDetailArrowDown"}/>
            <span style={{marginLeft:4}}>{t('mission_page.detail')}</span>
            </div>
            </div>
          }

        </Col>
      }


      {
        // /*
        // ScreenWidth >=480 &&
        <Col
        // span={PointsContainerSpan}
        // md={7} xs={7}
        className={"missionObjPointsContainer"} style={{...PointsContainerStyle}}>
          <Row style={{marginRight:10 , justifyContent:'center',alignItems:'center' , margin:'20px 0px'}}>
          <img src={"/images/icon_k.png"} className={'missionObjIconK'}/>
          <div style={{width:4}}/>
          <Col className={"missionObjPoints"}>{data.earn_point}</Col>
          </Row>

          <div className={'missionObjRedeemBTN'}
          onClick={()=>{
            //console.log(data);            
            onClickRedeem(data);
          }}>
          {t('mission_page.redeem')}
          </div>
        </Col>
        // */
      }

      </div>


      </Col>

      </Col>



      </Row>

      {
        isShowDetail?
        <Row>
        <div className={"missionObjDesc"} style={{margin:'5px 10% 10px 10%',display:'flex',width:'100%'}}
        dangerouslySetInnerHTML={{__html:
          i18n.language=='tw'?
          data.desc_zh:data.desc_en
        }}>
        </div>
        </Row>
        :null
      }

      </div>
    )
  }

  const Container=(data)=>{
    let objs =[];
    let hasPendingItem = false;
    if(data?.dataSet){
      data.dataSet.map((item,index)=>{
        if(item.is_referred){
          objs.push(DataRow(item,index))
        }else {
          if(data.onlyShow1Pending){
            if(hasPendingItem == false && item.status == 'pending'){
              objs.push(DataRow(item,index));
              hasPendingItem = true;
            }
          }else {
            objs.push(DataRow(item,index));
          }
        }
      })
    }
    // console.log(getWindowDimensions().width)
    return(
      <div className={'missionObjsContainer'}
      style={{ }}>
      {objs}
      {data.children}
      </div>
    )
  }

  const FriendProgress=()=>{
    let objs =[];
    // if(inviteData?.dataList){
    //   inviteData.dataList.map((item,index)=>{
    //     objs.push(Checkbox(item,index));
    //   })
    // }
    if(referralData?.total){
      let redemptionCount = referralData.redemption_count;
      let referralCount = referralData.referral_count;

      for (var i = 0; i < referralData.total; i++) {
        if(redemptionCount>0){
          objs.push(Checkbox('redeemed',i));
          redemptionCount-=1;
        }else if (referralCount>0) {
          objs.push(Checkbox('ready',i));
          referralCount-=1;
        }else {
          objs.push(Checkbox('',i));
        }
      }
    }
    return(
      <div className={'mission-friend-progress-container-div'} style={{margin:'0px 0px',border:'solid 0px',position:'relative',display:'flex',justifyContent:'center',alignItems:'center',}}>
      <div style={{width:'100%',borderBottom:'solid 1px #707070',position:'absolute'}} />
      <Row style={{width:'100%',border:'solid 0px #f00',position:'relative',display:'flex',justifyContent:'space-between'}}>
      {objs}
      </Row>
      </div>
    )
  }

  const Checkbox=(value,index)=>{
    let bgcolor = '#ffffff';
    // console.log(data)
    let isShowTick = false;
    if(value == 'redeemed'){
      bgcolor = '#848373';
      isShowTick = true;
    }
    else if(value == 'ready'){
      bgcolor = '#D4CF8E';
      isShowTick = true;
    }
    return(
      <div key={index} className={'mission-friend-progress-checkbox-outter-div'}>
      <div key={index} className={'mission-friend-progress-checkbox-inner-div'} style={{backgroundColor:bgcolor}}>
      {
        isShowTick&&
        // <span className="checkmark20"></span>
        <img src={'/images/mission_tick.png'}  className={'mission-friend-progress-checkbox-inner-tick'}/>
      }

      </div>
      </div>
    )
  }




  const UpperCheckPart=()=>{
    return(
      <div className={''} style={{ margin: '0 auto', padding: '0% 10% 0% 10%' ,border:'solid 0px'}}>
      <div style={{border:'solid 1px' , height:200}}>

      </div>
      </div>
    )
  }


  return (

    <AppLayout>
      <Spin spinning={loading}>
      {
        // <ImageSlider/>
      }
      {
        <ImageSlider3/>
      }
      {
        // i18n.language
      }
      {
        // <UpperCheckPart/>
      }
      {
        // <Display_breadcrumb />
      }
      {
        // <MissionProgress/>
      }
      {
        <TitleLine title={t('mission_page.invite_friend')}
        // rightComponent={
        //   <Button onClick={()=>{onClickMyReferralCode()}} className={'buttonStyle1'} type="primary" shape="round" style={{minWidth:100,alignSelf:'center',margin:'0px 0px'}}>
        //       {t('mission_page.my_referral_code')}</Button>
        // }
        rightComponent={
          <div onClick={()=>{onClickMyReferralCode()}}
          className={'mission-my-referral-code-div'}>
              {t('mission_page.my_referral_code')}</div>
        }
        />
      }

      {
        <Container dataSet={inviteData?.dataList} onlyShow1Pending={true}>
        <div  className={''} style={{ marginBottom:10, padding: '0% 20px 0% 20px',border:'solid 0px',margin:'10px 0px',display:'flex', justifyContent:'center' }}>
        <div style={{height:20}}/>
        <FriendProgress/>
        <div style={{height:20}}/>
        </div>
        </Container>
      }

      <div style={{height:40}}/>

      {
        <TitleLine title={t('mission_page.special_promotion')}/>
      }
      {
        <Container dataSet={specialDiscountData?.dataList}>
        </Container>
      }

      {
        <div  className={'missionObjsContainer'} style={{ marginBottom:10, border:'solid 0px',margin:'10px 0px',display:'flex', justifyContent:'flex-start' }}>
        <div onClick={onClickOffersTerms}style={{cursor:'pointer',color:'#3B95D1',fontSize:18 , borderBottom:'solid 1px #3B95D1',lineHeight:'100%'}}>
        {t('mission_page.offers_terms_conditions')}
        </div>
        </div>
      }
      <div style={{height:100}}/>
      {
        <ReferralCodeModal ref={referReferralCodeModal}
        inputCallback={onInputCallback}/>
      }

      </Spin>
    </AppLayout>

  )

}

export default MissionPage;
