import React, { Component, useEffect, useState } from 'react';
// import AppLayout from '../component/AppLayout'
import HomeAppLayout from '../component/HomeAppLayout'
import { useTranslation, initReactI18next } from "react-i18next";
import RegisterForm from '../component/form/RegisterForm';
//header page go back 
import { GoBackPageHeader } from '../component/PageHeader';
import { Row, Col, Typography } from 'antd';

const { Title } = Typography;

function ContactUsPage() {
  const { t, i18n } = useTranslation();

  return (
    <HomeAppLayout>
      <GoBackPageHeader />
      <Title>{t('contactus.title')}</Title>
      <Row gutter={[8, 24]} className="contact-info">

        <Col xs={6} md={12}>
          <Title level={5}>{t('contactus.tel')}</Title>
        </Col>
        <Col xs={18} md={12}>
          <Title level={5}>{t('companyInfo.tel')}</Title>
        </Col>

        <Col xs={6} md={12}>
          <Title level={5}>{t('contactus.email')}</Title>
        </Col>
        <Col xs={18} md={12}>
          <Title level={5}>
          <a href={"mailto:" + 'info@concordinsurance.com.hk'}>{t('companyInfo.email')}</a>
          </Title>
        </Col>

        <Col xs={6} md={12}>
          <Title level={5}>{t('contactus.address_office')}</Title>
        </Col>
        <Col xs={18} md={12}>
          <Title level={5}>{t('companyInfo.address_office')}</Title>
        </Col>

        {/* <Col md={12}>
          <Title level={5}>{t('contactus.hours')}</Title>
        </Col>
        <Col span={12}>
          <Title level={5}>xxx</Title>
        </Col> */}

      </Row>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14766.390538854332!2d114.18276957798115!3d22.293226665234293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404007b419627c1%3A0x3233125b8195427d!2z5ZCI576k5L-d6Zqq5pyJ6ZmQ5YWs5Y-4!5e0!3m2!1sen!2shk!4v1645765712995!5m2!1sen!2shk"
        style={{ width: '100%', height: '500px' }}
        loading="lazy" />
    </HomeAppLayout>
  )
}
export default ContactUsPage;