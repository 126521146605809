import React, { Component, useEffect, useState } from 'react';
import { Row, Col, Divider, Dropdown, Button } from 'antd'
import LangButton from './ChangeLangButton';
import { PhoneFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
//user dropdown
import UserDropdown from './UserDropdown';
// mobile components
import { Mobile_Header_indexBar,mobile_screen_size } from './MobileComponents';
//css



import BearCarousel, {elClassName,TBearSlideItemDataList, BearSlideItem} from 'bear-react-carousel';
import 'bear-react-carousel/dist/index.css';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const images = [
        // {id: 1, imageUrl: "https://dummyimage.com/900x400/dee2e6/6c757d.jpg"},
        {id: 2, imageUrl: "https://www.concordinsurance.com.hk/storage/media/Home/home-banner2x.jpg"},
        // {id: 3, imageUrl: "https://dummyimage.com/900x400/dee2e6/6c757d.jpg"},
    ];

const bearSlideItemData: TBearSlideItemDataList  = images.map(row => {
        // console.log('!!!!')
        let screenWidth = getWindowDimensions().width;
        let bannerHeight= 200;
        if(screenWidth<480){//mobile
          bannerHeight = screenWidth;
        }else {
          bannerHeight = screenWidth *0.3
        }
        return {
            key: row.id,
            children: <BearSlideItem imageUrl={row.imageUrl}/>,
            Xchildren: <BearSlideItem XimageUrl={row.imageUrl}>
            <img
            style={{
              width:screenWidth,
              height:bannerHeight,
              objectFit:'cover'
            }}
            src={row.imageUrl}/>
            </BearSlideItem>,
            // style:{border:'solid 1px #f00',objectFit:'cover'}
            // children:<img
            // style={{
            //   width:screenWidth,
            //   height:bannerHeight,
            //   objectFit:'cover'
            // }}
            // src={row.imageUrl}/>
        };
    });


const ImageSlider3 = () => {
  const [imageData,setImageData] = useState([
    {id:'1',imageUrl:"https://www.concordinsurance.com.hk/storage/media/Home/home-banner2x.jpg"}
  ]);

  useEffect(() => {

  }, [])

  // console.log(getWindowDimensions().width)
  let screenWidth = getWindowDimensions().width;
  let bannerHeight= 200;
  if(screenWidth<480){//mobile
    bannerHeight = screenWidth;
  }else {
    bannerHeight = screenWidth *0.3
  }

  const bearSlideItemData2: TBearSlideItemDataList  = imageData.map(row => {          
          let screenWidth = getWindowDimensions().width;
          let bannerHeight= 200;
          if(screenWidth<480){//mobile
            bannerHeight = screenWidth;
          }else {
            bannerHeight = screenWidth *0.3
          }
          return {
              key: row.id + bannerHeight,
              children: <BearSlideItem XimageUrl={row.imageUrl}>
              <img
              style={{
                width:screenWidth,
                height:bannerHeight,
                objectFit:'cover'
              }}
              src={row.imageUrl}/>
              </BearSlideItem>,
          };
      });

    return(
      <div style={{border:'solid 0px',height:bannerHeight}}>
       <BearCarousel
        slidesPerView={1}
        slidesPerGroup={1}
        isEnablePagination
        isEnableNavButton
        isEnableLoop={true}
        isEnableAutoPlay
        autoPlayTime={5000}
        moveTime={1000}
        // aspectRatio={{widthRatio: 2, heightRatio: 1}}
        data={bearSlideItemData2}
        // data={(data)=>{
        //   console.log(data)
        //   return { key:'1',children: <img/>}
        // }}
        // staticHeight={"400px"}
        // breakpoints={{
        //   480:{
        //     staticHeight: '480px',
        //     isEnableNavButton: true,
        //   },
        //   576: {
        //       staticHeight: '400px',
        //       isEnableNavButton: true,
        //   },
        //   996: {
        //       staticHeight: '500px',
        //       isEnableNavButton: true,
        //   },
        //   1200: {
        //       staticHeight: '600px',
        //       isEnableNavButton: true,
        //   }
        // }}
        renderNavButton={(toPrev, toNext) => {
            return (
                <div className={elClassName.navGroup} style={{border:'solid 0px #f00'}}>
                    <button type="button" className={elClassName.navPrevButton}
                    style={{border:'solid 0px #f00',backgroundColor:'#000'}}
                    onClick={() => toPrev()}>
                        <img src={'/images/concord_arrow_L.png'}
                        style={{height:30,width:30,border:'solid 0px',}}/>
                    </button>
                    <button type="button" className={elClassName.navNextButton}
                    style={{border:'solid 0px #f00',backgroundColor:'#000'}}
                    onClick={() => toNext()}>
                        <img
                        src={'/images/concord_arrow_L.png'}
                        style={{height:30,width:30,border:'solid 0px'}}/>
                    </button>
                </div>
            );
        }}

       />
     </div>
    )
}

export default ImageSlider3

/*
const ImageSlider = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [ScreenSize,setScreenSize]=useState();

  useEffect(() => {
    resize();
    window.addEventListener("resize", resize)
  }, []);

  const resize=()=>{
    console.log('!!!!!')
    setScreenSize({
      width:window.innerWidth,
      height:window.innerHeight,
    })
  }

  const slideImages = [
    {id:'1',name:'Slide 1',url:'https://concordinsurance.com.hk/images/home-banner2x.jpg',},
    {id:'2',name:'Slide 2',url:'https://concordinsurance.com.hk/images/home-banner2x.jpg',},
    {id:'3',name:'Slide 3',url:'https://concordinsurance.com.hk/images/home-banner2x.jpg',},
  ];

  const properties = {
    // indicators: (a,b)=>{
    //   console.log(a,b)
    //   return(
    //   <div style={{border:'solid 1px'}}>
    //   </div>
    // )},
    // duration: 5000,
    // transitionDuration: 500,
    // infinite: true,
    // prevArrow: <div style={{width: "30px", marginRight: "-30px"}}>
    //               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff">
    //               <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/>
    //               </svg>
    //            </div>,
    // nextArrow: <div style={{width: "30px", marginLeft: "-30px"}}>
    //               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff">
    //               <path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/>
    //               </svg>
    //            </div>
  };

  const EachImage=(item,index)=>{
    // console.log(ScreenSize?.width)
    let scaleHeight = 0;
    if(ScreenSize?.width){
      scaleHeight = ScreenSize.width * 1/2;
    }
    return(
      <div key={index}
      className="each-slide"
      style={{
        height:scaleHeight,
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        // backgroundSize: 'cover'
      }}>
      {
        // <div style={{'backgroundImage': `url(${item.url})`,backgroundSize: 'cover'}}>
        //   {
        //     // <span>{item.name}</span>
        //   }
        // </div>
      }

      {
        <img src={item.url} style={{width:'100%',height:scaleHeight ,objectFit: 'cover',}}/>
      }

      </div>
    )
  }

  const RenderSider=()=>{
    return(
      <Slide {...properties} easing="ease">
      {
        slideImages.map((item,index)=>EachImage(item,index))
      }
      </Slide>
    )
  }

  return (
    <div style={{ background: '#fff', padding: '0%', border: '0px solid #862633', fontSize: 20 , position:'relative' }}>
    {
      RenderSider()
    }
    </div>
  );
}


export default ImageSlider;
*/
