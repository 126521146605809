
export const update_user_profile_in_localstorage = (params) => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const { userinfo } = userData;
  const new_userinfo = { ...userinfo, ...params };
  const new_userData = { ...userData, userinfo: new_userinfo };
  localStorage.setItem('userData', JSON.stringify(new_userData));
  setTimeout(() => {
    window.location.reload();
  },1000)
}