import React, { Component, useEffect, useState } from 'react';
import { Layout, Button, Row, Col, Divider, Card, Breadcrumb } from 'antd';
import { useTranslation, initReactI18next } from "react-i18next";
// import * as ServiceCall from '../core/ServiceCall';
import { useHistory } from 'react-router-dom';
import AppLayout from '../component/AppLayout'
import PolicyDetail from '../component/PolicyDetail';
import { policy_title_style } from './UserPolicyPage';

function UserPolicyDetailPage() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [ScreenSize,setScreenSize]=useState();
  const resize=()=>{
    setScreenSize({
      width:window.innerWidth,
      height:window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener("resize", resize)
  }, []);


  const Display_breadcrumb = () => {
    return (
      <div className="policy_detail_display_breadcrumb">
        <Breadcrumb separator=">" style={{ padding: '1%' }} >
          <Breadcrumb.Item href="/dashboard">{t('header.home')}</Breadcrumb.Item>
          <Breadcrumb.Item href="/policy">{t('policy.title')}</Breadcrumb.Item>
          <Breadcrumb.Item >{t('policy.title_detail')}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
    )
  }

  return (
    <AppLayout>
      <Display_breadcrumb />

      <div style={{ margin: '0 auto', padding: '2% 10% 2% 10%' }}>
        {/* <button disabled style={policy_title_style}>{t('policy.title_detail')}</button> */}
        {/* <Card style={{ width: '100%', boxShadow: '3px 3px 9px #9BA3AA' }}> */}
        <PolicyDetail />
        {/* </Card> */}
      </div>
    </AppLayout>
  )

}

export default UserPolicyDetailPage;
