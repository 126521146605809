import { Menu, Layout } from 'antd';
import { Router, Route, Link, hashHistory, useHistory } from 'react-router-dom'

const { Sider } = Layout;
const { SubMenu } = Menu;

const AppLayoutSider = () => {
  const history = useHistory();

  const handleOnClick = (e) => {
    // console.log(e);
    // history.push(e.key);
    history.push({
      pathname: e.key, // link : '/xxxx'
        state: {data:'123'} // your data array of objects
    })
  }

  return (
    <>
      <Sider
        width={200}
        style={{ background: 'unset' }}
      >
        <Menu
          mode="inline"
          // defaultSelectedKeys={['10']}
          // defaultOpenKeys={['sub1']}
          style={{ height: '100%', borderRight: 0 }}
          onClick={handleOnClick}
        >
          <Menu.Item key="/home">Home page</Menu.Item>
          <Menu.Item key="/second">redux sample page</Menu.Item>
          <Menu.Item key="/websocket">websocket demo page</Menu.Item>
          {/* <SubMenu key="sub1" icon={null} title="subnav 1">
            <Menu.Item key="1"><Link to='/home'>home page</Link></Menu.Item>
            <Menu.Item key="2"><Link to='/second'>second page</Link></Menu.Item>
          </SubMenu> */}
        </Menu>
      </Sider>
    </>
  );
}
export default AppLayoutSider;