import { Row, Col, Divider, Dropdown, Button, Drawer, Layout } from 'antd'
import LangButton from './ChangeLangButton';
import { PhoneFilled, BarsOutlined, MenuOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
import React, { Component, useEffect, useState } from 'react';
//user dropdown
import UserDropdown from './UserDropdown';

const logo = '/images/logo.png';
// const homePhoto = '/images/homePhoto2.png';

export const mobile_screen_size = () => {
  // return (window.innerWidth < 480) ? true : false;
  return (window.innerWidth < 992) ? true : false;
}

export const Mobile_Header_indexBar = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  return (
    <>
      <Button type="text" onClick={showDrawer} icon={<MenuOutlined style={{ color: '#a29463', fontSize: '30px' }} />} />
      {/* <a onClick={showDrawer}><BarsOutlined /></a> */}
      <Drawer placement="right" onClose={onClose} headerStyle={{height:50 , borderBottom:'solid 1px #ddd'}} visible={visible}>
        <p>
          <a className="buttonLink" onClick={() => { history.push('/dashboard') }} type="link">{t('header.home')}&emsp;</a>
        </p>
        <p>
          <a className="buttonLink" onClick={() => { history.push('/policy') }} type="link">{t('header.policy')}&emsp;</a>
        </p>
        {/* <p>
          <a className="buttonLink" onClick={() => { history.push('/mission') }} type="link">{t('header.mission')}&emsp;</a>
        </p> */}
        <p>
          <a className="buttonLink" onClick={() => { history.push('/claims') }} type="link">{t('header.claims')}&emsp;</a>
        </p>
        <p>
          <a className="buttonLink" onClick={() => { history.push('/profile') }} type="link">{t('header.profile')}&emsp;</a>
        </p>
        <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',marginBottom:14}}>
          <span style={{color:'#4b4b4b',marginRight:10}}>{t('header.selectLanguage')}</span>
          {
            // <a className="buttonLink" onClick={() => {  }} type="link">{t('header.selectLanguage')}&emsp;</a>
          }
          <LangButton setVisible={setVisible} type={'menu_lang'} />
        </div>
        {
          // <p>
          //   <UserDropdown />
          // </p>
        }
        {
          // <p>
          //   <LangButton setVisible={setVisible} type={'index_bar'} />
          // </p>
        }

        {
          window.innerWidth < 992 ?
            <p>
              <a className="buttonLink" onClick={() => { history.push('/home') }} type="link">{t('header.logout')}</a>
            </p>
            :
            ''
        }
      </Drawer>
    </>
  );
}

export const Mobile_Login_Page_display = ({ conent, homePhoto, homeHeader, homeFooter }) => {
  const imgAndStyles = {
    // backgroundImage: `linear-gradient(to top, #3e3535, rgba(235, 247, 253, 0)),url(${photo})`,
    // backgroundImage: `url(${homePhoto})`,
    padding: '40% 3% ',
    minHeight: '100vh',
    backgroundRepeat: "no-repeat",
    backgroundSize: 'cover',
    textAlign: 'center',
    // backgroundPositionY: '60%',
    // boxShadow: '0 0 3px 3px #e8e8e8',
    backgroundImage: `linear-gradient(rgb(71 55 55), rgb(117 72 72)), url(${homePhoto})`,
    backgroundSize: 'cover',
    backgroundBlendMode: 'saturation',
  }
  return (
    <div style={imgAndStyles}>
      <Layout >
        {homeHeader}
        {conent}
        {homeFooter}
      </Layout>
    </div>
  );
}
