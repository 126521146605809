import axios from "axios";
import _ from 'lodash';
import qs from 'qs'
import getCurrentLanguage from '../component/GetCurrentLanguage'

function call() {
  // const baseURL = 'xxx';
  const baseURL = process.env.REACT_APP_API;
  const accessToken = localStorage.getItem('accessToken');
  // const i18nextLng = localStorage.getItem("i18nextLng") || "zh";
  const i18nextLng = getCurrentLanguage() || "zh";

  const headers = {
    "Accept": "application/json",
    "Accept-Language": i18nextLng,
    "Content-Type": "application/x-www-form-urlencoded",
    "Authorization": `Bearer ${accessToken}`
  };

  const userRequest = axios.create({
    baseURL: baseURL,
    headers: headers
  });

  return userRequest;
}

export const get = (path, data) => {
  return call().get(path, data);
}

export const post = (path, data) => {
  return call().post(path, data);
}

export const userLogin = (data) => {
  return call().post('/login', qs.stringify(data));
}

export const userRegister = (data) => {
  return call().post('/register', qs.stringify(data));
}

export const k11Recaptcha = (data) => {
  return call().post('kdp/get-captcha', qs.stringify(data));
}

export const k11Identity = (data) => {
  return call().post('kdp/verify-identity', qs.stringify(data));
}

export const bindMember = (data) => {
  return call().post('kdp/bind-member', qs.stringify(data));
}

export const userVerifyCode = (data) => {
  return call().post('/check-verifycode', qs.stringify(data));
}

export const forgetPassword = (data) => {
  return call().post('/forgot-password', qs.stringify(data));
}

export const resetPassword = (data) => {
  return call().post('/reset-password', qs.stringify(data));
}

export const requestLoginCode = (data) => {
  return call().post('/request-login-code', qs.stringify(data));
}

export const userLogout = (data) => {
  return call().post('/logout', qs.stringify(data));
}

export const resendVerifyCode = (data) => {
  return call().post('/resend-verifycode', qs.stringify(data));
}

export const activeAccount = (data) => {
  return call().post('/activate-account', qs.stringify(data));
}

export const getPolicyDetail = (data) => {
  return call().post('/policy/get-details', qs.stringify(data));
}

export const getPolicyDoc = (data) => {
  return call().post('/policy/get-documents', qs.stringify(data));
}

export const forgetUsername = (data) => {
  return call().post('/forgot-username', qs.stringify(data));
}

export const getK11Captcha = (data) => {
  return call().post('/kdp/get-captcha', qs.stringify(data))
}

export const verifyK11Identity = (data) => {
  return call().post('/kdp/verify-identity', qs.stringify(data))
}

export const bindK11Member = (data) => {
  return call().post('/kdp/bind-member', qs.stringify(data))
}

export const getMissionList = (data) => {
  return call().get('/mission-list', qs.stringify(data));
}

export const redeemMission = (data) => {
  return call().post('/redeem-mission', qs.stringify(data));
}

export const getReferralStatus = (data) => {
  return call().get('/referral-status', qs.stringify(data));
}
export const getReferralCode = (data) => {
  return call().get('/referral-code', qs.stringify(data));
}

export const updateUserInfo = (data) => {
  return call().post('/update-info', qs.stringify(data));
}

export const getClaimDetail = (data) => {
  // return call().post('/eclaims/get-details', qs.stringify(data));
  return call().post('/eclaims', qs.stringify(data));
}
