import { useTranslation, initReactI18next } from "react-i18next";


function GetCurrentLanguage() {
  // const { t, i18n } = useTranslation();

  // var currentLang = i18n.language;
  var currentLang = localStorage.getItem("i18nextLng")
  if (currentLang.includes('tw') || currentLang.includes('TW')) {
    currentLang = 'zh'
  }
  else if (currentLang.includes('zh') || currentLang.includes('ZH')) {
    currentLang = 'zh'
  }
  else {
    currentLang = 'en'
  }

  return currentLang;
}

export default GetCurrentLanguage
